.datepicker_outer_div {
  display: flex;
  align-items: center;

  .datepicker_icon {
    height: 18px;
    width: 14px;
    margin-right: 16px;
  }
}

.add-hover {
  fill: white;
}

.add-hover:hover {
  fill: var(--buttonBg);
  transition: all 0.2s ease-in-out;
}

.def-color {
  fill: white !important;
  cursor: not-allowed;
}

.recharts-sector {
  stroke: unset !important;
}

.datepicker {
  input::placeholder {
    color: #fff !important;
    font-size: 12px !important;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &_dates {
    display: none;
  }
}

.content {
  text-align: center;
}

.dashGraph {
  margin-top: 30px;
  display: flex;
  gap: 20px;

  @media screen and (max-width: 1320px) {
    flex-flow: wrap;
  }

  .ant-col {
    width: 100%;
  }

  &_leftsekelton {
    width: 100%;

    &_top {
      justify-content: space-between;
    }

    &_inner,
    &_top {
      display: flex;
      gap: 10px;

      .ant-skeleton-title {
        width: 100% !important;
        height: 30px !important;
      }
    }

    &_bottom {
      .ant-skeleton {
        width: 100%;

        .ant-skeleton-image {
          width: 100%;
          height: 300px;
        }
      }
    }
  }

  &_rightskeleton {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: max-content;
    width: 100%;

    @media screen and (max-width: 1320px) {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @media (max-width: 676px) {
      flex-direction: column;
    }

    .listingItems {
      .ant-skeleton-element {
        margin-bottom: 30px !important;
      }
    }

    .ant-skeleton-image {
      width: 200px !important;
      height: 200px !important;
      border-radius: 50% !important;
    }

    &_inner {
      margin-top: 30px;
    }

    &_right {
      display: flex;
      flex-flow: wrap;
      gap: 20px;
      justify-content: end;
    }
  }

  &_left {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .content {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-flow: wrap;
      justify-content: space-between;

      h2 {
        color: var(--sidebarLink);
        font-size: var(--headingTwo);
        font-weight: 600;
      }

      .customSelect {
        margin-top: 0;
        width: 130px;
        .ant-select-arrow {
          svg {
            path {

              fill: var(--sidebarLink);
            }
          }
        }

        .ant-select-selector {
          height: 30px !important;
        }
      }

      &_inner {
        display: flex;
        gap: 10px;
        flex-flow: wrap;
      }
    }

    .graph_size {
      div {
        color: var(--sidebarLink);
      }
    }

    .recharts-text {
      fill: var(--sidebarLink) !important;

    }

    .recharts-cartesian-grid-horizontal {
      line {
        stroke: var(--rechartLine);
      }
    }

    .ant-tabs-tab-btn {
      @media (max-width: 1400px) {
        font-size: var(--mediumParaGraph);
      }
    }

    .ant-tabs-nav {
      @media (max-width: 575px) {
        flex-wrap: wrap;
        gap: 20px;
      }
    }

    .ant-tabs-nav-operations {
      @media (max-width: 575px) {
        display: none !important;
      }
    }

    .ant-tabs-nav-list {
      transform: unset !important;
    }
  }

  &_right {
    background: var(--SideBarBg) !important;
    height: 100%;
    max-width: max-content;
    width: 100%;
    padding-top: 0 !important;

    @media (max-width: 1575px) {
      gap: 10px;
      padding: 20px;
    }

    @media screen and (max-width: 1320px) {
      max-width: 100%;
      display: flex;
      align-items: center;
    }

    @media (max-width: 676px) {
      flex-direction: column;
    }

    .radial_graph {
      max-width: 426px;
      width: 100%;
      margin: 0 auto;
      max-height: 400px !important;
      height: 100%;

      .recharts-wrapper {
        .recharts-surface {
          .recharts-text {
            fill: var(--sidebarLink);

          }
        }
      }

      @media screen and (max-width: 1120px) {
        height: 318px !important;
        max-width: 318px;
      }

      @media screen and (max-width: 991px) {
        height: 278px !important;
        max-width: 278px;
      }
    }

    &_inner {
      flex: 1;
      margin-top: -31px;

      @media screen and (max-width: 1320px) {
        margin-top: 0px;
      }

      h3 {
        text-align: center;
        padding-bottom: 10px;
        font-size: var(--headingFour);
        font-weight: 600;
        color: var(--sidebarLink);
      }

      &_listing {
        margin-left: 0 !important;
        margin-right: 0 !important;

        .listingItems {
          width: 100%;
          text-align: center;

          span {
            margin: 10px auto !important;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            display: block;
            margin-bottom: 10px;
          }

          &:first-of-type {
            span {
              background-color:#197CB9;
            }
          }

          &:nth-child(2) {
            span {
              background-color: #EF0073;
            }
          }

          &:nth-child(3) {
            span {
              background-color: #fd53a0;
            }
          }

          &:nth-child(4) {
            span {
              background-color: #FFBCDF;
            }
          }

          &:nth-child(5) {
            span {
              background-color: #C8D7EF;
            }
          }
        }

        p {
          font-size: 12px;
          font-weight: bold;
          color: var(--gray7D);
        }

        h4 {
          font-size: var(--largeParaGraph16);
          font-weight: 500;
          color: var(--sidebarLink);
          padding-top: 5px;
        }
      }
    }
  }
}