.accountType {
  h2 {
    color: var(--sidebarLink);
    font-size: var(--largSize50);
    font-weight: 900;
    line-height: normal;
    text-align: center;
  }

  &_switch {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    &_head {

      padding: 4px 8px;

      >div {
        display: flex;
        align-items: center;
        gap: 10px;

        h5 {
          color: var(--sidebarLink);
        }
      }

      &.pink {
        transition: .3s ease-in-out;
        box-shadow: 0px 2px 1px 2px var(--pinkColor);
        border-radius: 10px;
        // background: radial-gradient(88.29% 27.9% at 100% 0%, #ff008426 0%, #ff008300 100%), radial-gradient(90.19% 28.5% at 0% 100%, #ff008426 0%, #ff008300 100%), linear-gradient(0deg, #0c0c0c, #0c0c0c);
        background-color: var(--planBg);
        h5 {
          // color: white;
        }
      }
    }
  }

  &_cards {
    padding: 0 20px;
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 30px;

    @media (max-width: 1699px) {
      gap: 10px;
    }

    @media screen and (max-width: 1399px) {
      flex-wrap: wrap;
    }
  }

  &_btn {
    max-width: 245px;
    width: 100%;
    margin: auto;
    margin-top: 60px;
  }
}

.dashAcType {
  h2 {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .accountType_cards {
    margin-top: 30px !important;
  }
}