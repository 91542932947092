.dragDrop {
  margin-top: 30px;

  h3 {
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    display: inline-block;
    margin-bottom: 9px;
    color: var(--gray7D) !important;
    line-height: 20px;
    white-space: nowrap;
    padding: 0 4px;
    border-radius: 15px;

    span {
      color: var(--pinkColor);
    }
  }
}

.ant-upload {
  background: transparent !important;
  border: 1px dashed var(--btndarkred) !important;
  min-height: 107px !important;
}

.ant-upload-text {
  font-size: var(--largeParaGraph);
  font-weight: 400;
  color: var(--grayColor) !important;

  span {
    color: var(--pinkColor) !important;
  }
}

.file-list {
  margin-top: 10px;

  h4 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(65, 65, 65, 1) !important;

    span {
      color: var(--gray7D);
      font-size: 14px;
      // flex-grow: 1;
    }

    button {
      margin-left: 10px;
    }
  }
}


.remove-icon {
  margin-left: 10px;
  color: red;
  cursor: pointer;
  font-size: 1.2em;
}
