.selectCard {
  background-image: url(../../../Assets/images/SelectBg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: 30px;
  border-radius: 30px;
  h5 {
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      color: var(--pinkColor);
      font-size: var(--headingFour);
    }
  }
  h4 {
    font-size: var(--heading26);
    font-weight: 600;
    color: var(--white);
    margin: 11px 0;
  }
  p {
    font-size: var(--headingFour);
    font-weight: 400;
    color: var(--midGrayColor);
    margin-bottom: 12px;
  }
  &_goNext {
    font-size: var(--headingFour);
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 0.4s ease-in-out;
    &:hover {
      gap: 40px;
    }
  }
}
