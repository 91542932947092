.contact {
  &_inner {
    max-width: 772px;
    width: 100%;
    margin: auto;
    border: 1px solid var(--btndarkred);
    box-shadow: inset var(--boxShadow2);
    padding: 50px;
    border-radius: 30px;

    @media (max-width: 767px) {
      padding: 36px;
    }

    @media (max-width: 575px) {
      padding: 24px;
    }

    h4 {
      font-size: var(--headingTwo);
      font-weight: 700;
      color: var(--sidebarLink);
    }

    p {
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      color: var(--grayColor);
    }

    &_inputs {
      display: flex;
      gap: 20px;

      @media screen and (max-width: 676px) {
        flex-flow: wrap;
      }
    }

    .customInput {
      width: 100%;

      @media (min-width: 680px) {
        margin-top: 50px;
      }
    }

    &_button {
      margin-top: 0 !important;
      height: 50px;
      background: var(--pinkColor) !important;
      border-radius: 25px;
      max-width: 150px !important;

      @media (max-width:575px) {
        max-width: 100% !important;
      }

      p {
        width: 50px;
      }
    }
  }
}

.margin-field {
  margin-top: 30px;
}

.copy_mail {
  cursor: pointer;
}

.contact_action {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width:575px) {
    flex-direction: column;
  }
}

.copy-email-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  border-radius: 25px;
  background-color: var(--buttonBg);
  max-width: 240px;
  width: 100%;
  height: 50px;

  @media (max-width:575px) {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .copy-icon{
    svg{
      path{
        fill: white;
      }
    }
  }
}

.email-address {
  font-size: 14px;
  color: white;
  word-break: break-all;
  flex-grow: 1;
  padding-right: 10px;

}

.copy-icon {
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease;
}

.copy-icon:hover {
  transform: scale(1.1);
}

.copy-icon:active {
  transform: scale(0.95);
}