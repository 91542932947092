.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24.5px 24.5px 24.5px 231.5px;
  background: var(--SideBarBg);
  color: var(--sidebarLink);
  border-top: 1px solid var(--borderColor2);
  margin-top: auto;
  position: relative;
  @media (max-width: 767px) {
    padding-left: 24.5px;
  }

  @media (max-width: 575px) {
    flex-direction: column-reverse;
    padding: 20px;
  }

  .center-text {
    flex-grow: 1;
    text-align: center;

    @media (max-width: 767px) {
      text-align: start;
    }
  }

  .content {
    display: flex;
    gap: 30px;

    @media (max-width: 575px) {
      margin-bottom: 10px;
    }
  }

  span,
  p {
    margin: 0;
    cursor: pointer;
  }
}

.mail {
  cursor: pointer;
}