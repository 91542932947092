.dashboard {

  &_bannerSkeleton,
  &_bannerSkeleton>div {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    gap: 10px;
    align-items: center;
  }

  &_banner {
    border-radius: 30px;
    overflow: hidden;
    background-color: var(--bannerColor);
    position: relative;
    z-index: 2;
    box-shadow: 8px 4px 5px rgba($color: #000000, $alpha: .08);
    padding: 22px 25px;

    &::before {
      position: absolute;
      content: '';
      background-image: url('../../Assets/images/welcome-banner-bg.png');
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      right: 0;
      width: 555px;
      height: 112px;
      z-index: 1;

      @media (max-width:1199px) {
        right: -240px;
        bottom: 0px;
      }
    }

    &::after {
      @media (max-width:1399px) {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: inherit;
        backdrop-filter: blur(3px);
        z-index: 1;
        top: 0;
        left: 0;

        @media (max-width:1199px) {
          backdrop-filter: blur(20px);
        }
      }
    }

    // padding: 23px 60px;
    // display: flex;
    // align-items: center;
    // flex-flow: wrap;
    // justify-content: space-between;
    // background-image: url(../../Assets/images/DashboardBanner.svg);
    // background-color: ;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // .banner-bg {
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   // width: 400px;
    //   z-index: 0;

    //   @media (max-width:1599px) {
    //     max-width: 500px;
    //     right: -20px;
    //     // top: 40px;
    //   }

    // }

    // @media screen and (max-width: 767px) {
    //   gap: 20px;
    //   padding: 23px 20px;
    //   margin: 0;
    // }

    .dashboard_banner_in {
      // padding-left: 200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      position: relative;
      // @media (max-width:1599px) {
      //   padding-left: 150px;
      // }

      @media (max-width:1199px) {
        flex-direction: column;
        align-items: normal;
      }

      @media (max-width:575px) {
        align-items: center;
      }

      >div {
        h3 {
          font-size: var(--heading30);
          font-weight: 600;
          color: var(--welcomeText);
          margin-bottom: 12px;

          @media (max-width:575px) {
            text-align: center;
          }

        }

        p {
          color: var(--gray7D);
          font-size: var(--largeParaGraph16);
          font-weight: 400;

          @media (max-width:575px) {
            text-align: center;
          }
        }
      }
    }

    // >div {
    //   @media screen and (max-width: 767px) {
    //     padding-left: 0px;
    //   }
    // }

    // .banner-logo {
    //   max-width: 100px;
    //   width: 100%;
    //   position: absolute;
    //   z-index: 5;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   left: 5%;

    //   @media screen and (max-width: 767px) {
    //     max-width: 100px;
    //     position: unset;
    //   }
    // }

    .customButton {
      max-width: 154px;
      height: 50px;
      background-color: var(--buttonBg);
      color: var(--white);
      position: relative;

      @media screen and (max-width: 767px) {
        height: 30px;
      }

      &:hover {
        background-color: transparent;
        border-color: var(--buttonBg);

        p {
          color: var(--buttonBg) !important;

        }

        svg {

          path {
            fill: var(--buttonBg)
          }
        }
      }

      // @media (max-width:1399px) {
      //   margin-inline: auto;
      // }

      @media screen and (max-width: 991px) {
        margin-top: 32px !important;
      }

      @media screen and (max-width: 767px) {
        margin-top: 10px !important;
      }
    }




  }

  .dashCard {
    display: flex;
    margin-top: 30px;
    gap: 20px;
    flex-flow: wrap;

    @media screen and (max-width: 767px) {
      margin-top: 0;
      gap: 0px;
    }

    &_inner {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      min-width: 300px;

      @media screen and (max-width: 767px) {
        min-width: unset;
      }

      button {
        color: var(--white);
      }

      &_left {
        &_stepHeading {
          color: var(--midGrayColor);
          font-weight: 400;
          font-size: var(--largeParaGraph16) !important;
          padding-bottom: 20px;
        }

        h3 {
          font-size: var(--headingTwo);
          font-weight: 600;
          padding-bottom: 20px;
        }

        p {
          color: var(--midGrayColor);
          font-weight: 400;
          font-size: 12px;
          max-width: 333px;
          font-size: var(--regularParagraph);
        }
      }

      &_right {
        max-width: 200px;
        display: flex;
        justify-content: end;
        width: 100%;

        @media screen and (max-width: 1581px) {
          justify-content: left;
        }

        button {
          width: 100%;
          font-size: var(--mediumParaGraph);
          font-weight: 400;
          height: 36px;
        }
      }
    }
  }

  .disable-cursor {
    cursor: not-allowed;
  }

  .userLogCard {
    padding-top: 30px;

    .cardskeleton {
      gap: 20px;
      display: flex;
      overflow: hidden;
    }

    h3 {
      color: var(--sidebarLink);
      font-size: var(--headingTwo);
    }

    &_inner {
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: first baseline;

      &_data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        min-width: 370px;
        max-width: 370px;
        width: 100%;

        @media (max-width: 1399px) {
          max-width: 48%;
          flex: unset;
        }

        @media (max-width: 575px) {
          min-width: 300px;
        }
      }
    }

    &_outer {
      width: 100%;
      overflow: hidden;
      position: relative;
    }
  }
}