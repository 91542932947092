.item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  padding: 5px 10px;
  color: var(--sidebarLink);
  path {
    fill: var(--sidebarLink) !important;
  }

  svg {
    height: 17.3px;
    width: 17.3px;

     path {
      fill: var(--pinkColor) !important;
    }
  }

  button {
    outline: unset;
    text-decoration: unset;
  }

  &_fa {
    height: 17.3px !important;
    width: 17.3px !important;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block !important;
  max-width: 300px;
  width: 100%;
}

.bucket {
  align-items: center;
  display: flex;
  gap: 10px;
}