.payment-process-modal {
    .modal-content {
        display: flex;

        flex-direction: column;
        align-items: center;

        .circle {
            position: relative;

            .loader-circle {
                width: 100px;
                height: 100px;
                border: 3px solid #ddd;
                border-top-color: var(--pinkColor);
                border-right-color: var(--pinkColor);
                border-radius: 50%;
                animation: circleAnim 1s linear infinite;
                margin: 10px auto;
            }

            @keyframes circleAnim {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            .proces_img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }


        h2 {
            margin: 10px 0;
            font-size: var(--headingTwo);
        }

        p {
            font-size: var(--largeParaGraph16);
            text-align: center;
            line-height: 18px;
        }

        .ok_btn {
            max-width: 217px !important;
            width: 100%;
        }
    }
}