.audioDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 20px;
  audio {
    width: 100%;
  }
}
.unpreviewable {
  &_image {
    max-width: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px;
    border: 1px solid var(--tooltipBg);
    border-radius: 50%;
    margin: auto;
    > div {
      background: var(--pinkColor);
      max-width: 56.63px;
      width: 100%;
      height: 56.63px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 22px;
      }
    }
  }

  h4 {
    font-size: var(--headingTwo);
    font-weight: 700;
    color: var(--lableColor);
    margin-top: 30px;
  }
}

.loading-overlay {
  height: 500px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}

.loading-overlay p {
  align-content: center;
}
