.billing {
  &_bottom {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    gap: 20px;
    margin: 20px 0;
    justify-content: space-between;
    position: relative;

    h3 {
      color: var(--sidebarLink);
      font-size: var(--headingTwo);
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
    }

    .customInput {
      margin: 0;
    }
  }


}

.billing_action {
  display: flex;
  flex-shrink: 0;

  .billing_dropdown {
    width: 157px !important;
    width: 100% !important;
    margin-right: 10px;
    padding: 8px 35px;
    background: var(--buttonBg);
    border: 1px solid transparent;
    display: flex;
    font-size: 16px;
    font-weight: 600;
  }

  .customButton-icon {
    svg {
      path {
        // fill: var(--sidebarLink);
      }
    }
  }
}