.progressBar {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.uploadPopup {
  background: var(--dashcardbg);
  border-radius: 30px;
  position: absolute;
  z-index: 999;
  right: 10px;
  bottom: 10px;
  max-width: 426px;
  width: 100%;
  padding: 5px;

  @media screen and (max-width: 444px) {
    right: 0;
    bottom: 0;
  }

  &_icon {
    svg {
      cursor: pointer;

      path {
        fill: var(--sidebarLink);
      }
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    .title {
      color: var(--sidebarLink);
      font-size: var(--headingTwo);
      font-weight: 700;
    }

    >div {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        path {
          fill: var(--pinkColor);
        }
      }
    }
  }

  .closed {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  .open {
    max-height: 417px;
    height: 100%;
  }

  .uploadList {
    max-height: 272px;
    overflow: scroll;
  }

  .uploadSec {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 20px;

    .cancel-btn {}

    &:last-child {
      padding-bottom: 0;
    }

    >div:first-child {
      width: 100%;
    }

    &_top {
      svg {
        width: 18px;
        height: 18px;

        path {
          fill: var(--pinkColor);
        }
      }

      img {
        width: 18px;
        height: 18px;
      }
    }

    &_top,
    &_bottom {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .progressBar {
      .loader-line {
        max-width: 90% !important;
      }
    }

    &_bottom {
      justify-content: space-between;
    }

    h4 {
      font-size: var(--largeParaGraph);
      font-weight: 600;
      color: var(--sidebarLink);

      svg {
        path {
          fill: var(--pinkColor);
        }
      }
    }

    p {
      color: var(--grayColor);
      font-size: var(--largeParaGraph16);
      font-weight: 400;

      span {
        text-decoration: underline;
      }
    }
  }
}

.uploadTabs {
  padding: 0 15px;
  max-height: 416px;
  transition:
    max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out;

  .ant-tabs-tab {
    background: #D3D3D3;
    border-radius: 20px;
    padding: 13px 12px !important;
    margin-left: 6px !important;

    .ant-tabs-tab-btn {
      color: #A9A9A9;
    }

    &:first-child {
      margin-left: 0 !important;
    }

    &-active {
      background: var(--pinkColor);
      // color: var(--);

      .ant-tabs-tab-btn {

        color: white !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab-btn {
    line-height: normal;
    font-size: var(--largeParaGraph16);
    font-weight: 600;
    color: var(--white);
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.uploadingStatus {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 13px 14px;
  border-radius: 0 0 26px 26px;
  background: #2e2d2d;
  backdrop-filter: blur(10px);

  h5 {
    font-size: var(--largeParaGraph16);
    font-weight: 600;
    color: var(--white);
  }

  p {
    font-size: var(--mediumParaGraph);
    color: var(--grayColor);
    font-weight: 400;
  }
}

.uploadPopup_right {
  display: flex;
  align-items: center;
  gap: 20px !important;
}