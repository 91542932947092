.notificationsTable {
  .options{
    justify-content: center;
  }
  .ant-table-thead tr th {
    &:first-child {
      text-align: left;
    }
    &:nth-child(3) {
      text-align: center;
    }
    &:last-child{
      text-align: center !important;
    }
  }

  .ant-table-body tr td {
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: var(--pinkColor);
      }
    }
    &:first-child {
      text-align: left;
    }
  }
}
