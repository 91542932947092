.common-modal {
  &.launchModal {
    width: 100% !important;
    max-width: 712px !important;

    .ant-modal-content {
      padding: 68px 40px !important;

      @media (max-width: 1399px) {
        padding: 20px;
      }
    }

    .ant-modal-body {
      .modal-header {
        display: none;
      }
    }

    .modal-content {
      padding-top: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal-content-paragraph {
        display: none;
      }

      .loginBannerImg {
        max-width: 131px;
      }

      h1 {
        font-size: 48px;
        font-weight: 900;
        line-height: 104px;
        text-align: center;
        margin: 51px 0 30px 0;

        @media (max-width: 1399px) {
          font-size: 24px;
          line-height: 32px;
          margin: 41px 0 24px 0;
        }

        @media (max-width: 575px) {
          font-size: 20px;
          line-height: 30px;
          margin: 20px 0;
        }

        span {
          color: var(--pinkColor);
        }
      }

      p {
        font-size: var(--regularParagraph);
        font-weight: 400;
        line-height: normal;
        text-align: center;
      }
    }
  }
}
