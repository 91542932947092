.dashLayout {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;

  &_inner {
    display: flex;
  }

  &_content {
    width: calc(100% - 316px);

    // padding: 0 28px;
    @media screen and (max-width: 992px) {
      width: 100%;
    }

    @media screen and (max-width: 490px) {
      // padding: 0 10px;
    }

    &_body {


      height: calc(100vh - 93px);
      min-height: calc(100vh - 93px);
      background: var(--dashLayout);
      overflow-y: auto;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      display: flex;
      flex-direction: column;

      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        width: 330px;
        height: 500px;
        background-image: url('../../../Assets/images/dashlaoyout-shadow.png');
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
        opacity: 0.6;
        transform: translateY(-55px);
      }

      &_in {
        padding: 30px 28px 20px 28px;

        @media (max-width: 767px) {
          padding: 15px;
          margin: 0;
        }

        @media screen and (max-width: 490px) {
          padding: 15px 10px;
        }
      }
    }
  }
}