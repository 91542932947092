.reportModal {
  max-width: 600px !important;

  .ant-modal-body {
    .modal-header {
      display: none;
    }
  }

  .modal-content-paragraph {
    display: none;
  }

  &_inner {
    &_inputs {
      display: flex;
      gap: 20px;

      @media screen and (max-width: 676px) {
        flex-flow: wrap;
      }

      .customInput {
        width: 100%;
      }
    }

    h4 {
      font-size: var(--headingTwo);
      font-weight: 700;
      color: var(--sidebarLink);
    }

    p {
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      color: var(--grayColor);
    }

    &_button {
      margin: auto;
      margin-top: 30px;

      p {
        width: 50px;
      }
    }
  }
}

.margin-field {
  margin-top: 40px;
}