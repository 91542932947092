// @import url("https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=FontName:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  margin: 0;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .light {
    // Color vars//
    --shareFolder:#d0deef;
    --tooltipBack: #d0e3f7;
    --otpInput: #c8d7ef;
    --headerIconHover: #0e6aad;
    --buttontext: #0e1120;
    --pink: #ff0083;
    --bellColor: #8d96aa;
    --whiteColor: #fff;
    --sidebarLink: #0e1120;
    --gray7D: #67717d;
    --buttonBg: #222d37;
    --storageClr: #67717d;
    --mainBackGround: #e8f2ff;
    --bannerColor: #e0ecfa;
    --welcomeText: #0e1120;
    --footer: #e0ecfa;
    --sidebarBorder: #d1dde9;
    --dashcardbg: #d1dde9fb;
    --grayColor: #67717d;
    --cardBorder: #7070703c;
    --tableHead: #d0deef;
    --tableData: #e0ecfa;
    --tableHeadText: #0e1120;
    --borderColor2: #7070703c;
    --rechartLine: #000;
    --dropdownBg: #e0ecfa;
    --dropdownBtn: #222d37;
    --planBg: #e0ecfa;
    --planBg1: #e0ecfa;
    --progressInner: #d0e3f7;
    --progressBar: #197cb9;
    --selectBg: transparent;
    --selectBorder: #d1dde9;
    --themeColor: #d0deef;
    --toggleBtnBG: #0e6aad;
    --toggleBtn: black;
    --midGrayBorder: #d1dde9b2;
    --pinkColor: #0e6aad;
    --midGrayColor: #d6cccc;
    --lableColor: #000;
    --lableBackground: #e8f2ff;
    --copyBtn: #ff0083;
    --dashLayout: url("./Assets/images/dashlayout-bg.png");
    --addFolderClr: #0e1120;
    --whitePink: #fff;
    --blackPink: #000;
    --hoverTable: #58505445;
    --accountType: #86919b;
    --toastColor: #d0e3f7;
    --footerBg: #fff;
    --ApiBG: rgba(98, 97, 97, 0.11);
    --borderColor: #2b2b2b;
    --offWhite: #f9f9fd;
    --black: #000000;
    --white: #ffffff;
    --tooltipBg: #3f0624;
    --error: #f93232;
    --success: #00b25d;
    --mainBg: #041410;
    --cardBg: #143936;
    --loginCardBg: #143936;
    --mainTextColor: #00f2b5;
    --mainGrayText: #9ba0a6;
    --orangeMatrix: #ff9f3a;
    --pinkMatrix: #f458a7;
    --lightRedMatrix: #ff6a4d;
    --purpleMatrix: #7c5caf;
    --grayBtnBg: #202c3a;
    --inputBg: #171717;
    --textShadow: #008bae83;
    --disabledButton: #5500ff10;
    --SideBarBg: #e8f2ff;
    --whiteFFF: #fff;
    --greenbg: #529e00;
    --orangeText: #fe9f00;
    --redColor: red;
    --textGray: #b5b7c8;
    --subtextDarkGray: #636674;
    --purple: #803cf0;
    --purpletextbg: #1f192a;
    --editBtnbg: #15202d;
    --textdarkGray: #5f626f;
    --btndarkred: #ff00834d;
    --btnredbg: #ff00831a;
    --modalBg: #2b2b2b;
  }

  .dark {
    // Color vars//
    --whitePink: #ff0083;
    --blackPink: #ff0083;
    --tooltipBack: rgb(36, 36, 36);
    --shareFolder:#171717;

    --toastColor: radial-gradient(
        88.29% 27.9% at 100% 0%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 132, 0.246) 100%
      ),
      radial-gradient(
        90.19% 28.5% at 0% 100%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      linear-gradient(0deg, #0c0c0c, #0c0c0c);
    --buttontext: #ff0083;
    --headerIconHover: #fff;
    --accountType: radial-gradient(
        88.29% 27.9% at 100% 0%,
        rgba(255, 0, 132, 0.1490196078) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      radial-gradient(
        90.19% 28.5% at 0% 100%,
        rgba(255, 0, 132, 0.1490196078) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      linear-gradient(0deg, #0c0c0c, #0c0c0c);
    --addFolderClr: #ff0083;
    --pink: #ff0083;
    --tableData: transparent;
    --themeColor: #ff0083;
    --toggleBtnBG: #ff0083;
    --copyBtn: #fff;
    --sidebarLink: #fff;
    --gray7D: #fff;
    --storageClr: #d6cccc;
    --mainBackGround: #000;
    --bannerColor: #1d0b14;
    --welcomeText: #ff0083;
    --bellColor: #fff;
    --footer: #0c0c0c;
    --sidebarBorder: transparent;
    --cardBorder: #2a2a2a;
    --tableHead: #000;
    --tableHeadText: #d6cccc;
    --rechartLine: #ccc;
    --dropdownBg: #171717;
    --grayColor: #707070;
    --dropdownBtn: #282626;
    --planBg: #111;
    --planBg1: radial-gradient(
        88.29% 27.9% at 100% 0%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      radial-gradient(
        90.19% 28.5% at 0% 100%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      linear-gradient(0deg, #0c0c0c, #0c0c0c);
    --progressInner: #414141;
    --progressBar: #fff;
    --selectBg: #171717;
    --selectBorder: transparent;
    --toggleBtn: #fff;
    --dashLayout: #000;
    --ApiBG: rgba(98, 97, 97, 0.486);
    --pinkColor: #ff0083;
    --midGrayColor: #d6cccc;
    --lableColor: #d6cccc;
    --lableBackground: #000000;
    --midGrayBorder: #7070703c;
    --borderColor: #2b2b2b;
    --borderColor2: #2a2a2a;
    --offWhite: #f9f9fd;
    --black: #000000;
    --white: #ffffff;
    --tooltipBg: #3f0624;
    --error: #f93232;
    --success: #00b25d;
    --mainBg: #041410;
    --cardBg: #143936;
    --loginCardBg: #143936;
    --mainTextColor: #00f2b5;
    --mainGrayText: #9ba0a6;
    --orangeMatrix: #ff9f3a;
    --pinkMatrix: #f458a7;
    --lightRedMatrix: #ff6a4d;
    --purpleMatrix: #7c5caf;
    --grayBtnBg: #202c3a;
    --inputBg: #171717;
    --otpInput: #171717;
    --textShadow: #008bae83;
    --disabledButton: #5500ff10;
    --dashcardbg: #111111;
    --SideBarBg: radial-gradient(
        88.29% 27.9% at 100% 0%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      radial-gradient(
        90.19% 28.5% at 0% 100%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      linear-gradient(0deg, #0c0c0c, #0c0c0c);
    --footerBg: radial-gradient(
        88.29% 27.9% at 100% 0%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      radial-gradient(
        90.19% 28.5% at 0% 100%,
        rgba(255, 0, 132, 0.15) 0%,
        rgba(255, 0, 131, 0) 100%
      ),
      linear-gradient(0deg, #0c0c0c, #0c0c0c);
    --blue: #4285f4;
    --boxShadow: 0 10px 30px 0 #0c2e3262;
    --boxShadow2: 0 0 37px -19px #ff0083;
    --buttonBg: #ff0083;
    --hoverTable: #3927307d;
    --buttonHoverBg: #a50055;
    --headerBg: linear-gradient(
      rgba(245, 247, 250, 0.12),
      rgba(245, 247, 250, 0.06),
      rgba(245, 247, 250, 0)
    );
    --whiteFFF: #fff;
    --greenbg: #529e00;
    --orangeText: #fe9f00;
    --redColor: red;
    --textGray: #b5b7c8;
    --subtextDarkGray: #636674;
    --purple: #803cf0;
    --purpletextbg: #1f192a;
    --editBtnbg: #15202d;
    --textdarkGray: #5f626f;
    --btndarkred: #ff00834d;
    --btnredbg: #ff00831a;
    --modalBg: #2b2b2b;
  }

  // Typography //
  --largSize100: 100px;
  --largSize77: 77px;
  --largSize50: 50px;
  --largSize40: 40px;
  --headingOne: 32px;
  --heading30: 30px;
  --heading28: 28px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --heading26: 26px;
  --headingFour: 20px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --c: 14px;
  --smallParagraph: 13px;
  --xtrasmallParagraph: 12px;
}

/* Responsive styles */
@media screen and (max-width: 991px) {
  :root {
    --largSize100: 78px;
    --largSize77: 57px;
    --headingTwo: 18px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --largSize100: 58px;
    --largSize77: 50px;
    --largSize50: 40px;
    --largSize40: 30px;
    --headingOne: 28px;
    --heading30: 26px;
    --heading28: 24px;
    --headingTwo: 16px;
    --headingThree: 22px;
    --heading26: 20px;
    --headingFour: 18px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --largSize100: 48px;
    --largSize77: 40px;
    --largSize50: 30px;
    --largSize40: 20px;
    --headingOne: 24px;
    --heading30: 22px;
    --heading28: 20px;
    --headingTwo: 14px;
    --headingThree: 20px;
    --heading26: 17px;
    --headingFour: 16px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 13px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

body {
  margin: 0;
  padding: 0;
  background: var(--mainBackGround);
  color: var(--whiteFFF);
  // font-family: "KH Teka";
  // font-family: "Mada", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: 4px;
  height: 100vh;
  overflow: auto;
}

body::-webkit-scrollbar {
  width: 3px;
  background-color: var(--mainBg);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--cardBg);
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c2185f90;
  border-radius: 10px;
  border: 1px solid transparent !important;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

////// Antd Table Scrollbar //////////////
:where(.css-dev-only-do-not-override-ccdg5a)
  .ant-table-wrapper
  .ant-table::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

:where(.css-dev-only-do-not-override-ccdg5a)
  .ant-table-wrapper
  .ant-table::-webkit-scrollbar-track {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-ccdg5a)
  .ant-table-wrapper
  .ant-table::-webkit-scrollbar-thumb {
  background-color: var(--buttonHoverBg);
  border-radius: 6px;
}

:where(.css-dev-only-do-not-override-ccdg5a)
  .ant-table-wrapper
  .ant-table::-webkit-scrollbar-thumb:hover {
  background-color: darkred;
}

/* Firefox scrollbar styling */
:where(.css-dev-only-do-not-override-ccdg5a) .ant-table-wrapper .ant-table {
  scrollbar-width: thin !important;
  scrollbar-color: var(--buttonHoverBg) var(--grayBtnBg);
}

/* Customize scrollbar for Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.ant-table-container::-webkit-scrollbar-track {
  background: var(--grayBtnBg) !important;
}

.ant-table-container::-webkit-scrollbar-thumb {
  background: var(--buttonHoverBg) !important;
  border-radius: 6px !important;
  width: 2px !important;
}

.ant-table-container::-webkit-scrollbar-thumb:hover {
  background: var(--buttonHoverBg) !important;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  // font-family: "KH Teka",sans-serif !important;
  font-family: 'KH Teka';
  // font-family: "Mada", sans-serif;
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    // monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-50px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.02);
  }
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(20px);
  }
}

@keyframes scale {
  100% {
    transform: scale(1.04);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px var(--inputBg) inset;
  -webkit-text-fill-color: var(--whiteFFF) !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.flatpickr-day:hover {
  background-color: var(--buttonBg) !important;
}

.slick-disabled {
  display: none !important;
}

.slick-track {
  display: flex !important;
  gap: 16px;
  transition: transform 0.4s ease-out;
  margin: 0 !important;
}

.slick-slide {
  flex: 1 1 auto;
  min-width: 0;
  box-sizing: border-box;
  margin-right: 20px;

  @media (min-width: 994px) and (max-width: 1100px) {
    width: 486px !important;
    min-width: unset !important;
  }

  > div {
    display: flex;
    justify-content: center;
  }
}

.slick-slider {
  width: 100% !important;
}

.slick-dots {
  display: none !important;
}

.slick-prev {
  left: 30px !important;
  z-index: 99 !important;
  box-shadow: 0 0px 65px 112px #000;

  @media screen and (max-width: 991px) {
    left: 20px !important;
  }

  @media screen and (max-width: 590px) {
    left: 10px !important;
  }
}

.slick-next {
  right: 30px !important;
}

.slick-arrow {
  background: black !important;

  &::before {
    font-size: 40px !important;
    opacity: 1 !important;
  }
}

.slick-disabled {
  display: none !important;
}

.ant-upload-list {
  display: none;
}

.modal_lock {
  text-align: center;

  .ant-modal-close-x {
    svg {
      color: #fff;
    }
  }

  .ant-modal-content {
    background-color: #000 !important;
    border-radius: 30px;
    overflow: hidden;
  }

  .imgdive {
    border: 2px solid #ff0083;
    width: 100px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px !important;
    margin: auto;

    img {
      margin: 17px;
    }
  }

  .modal_main {
    padding: 30px;

    h4 {
      color: #fff;
      font-size: var(--headingTwo);
      font-weight: 700;
      margin-bottom: 26px;
    }

    .crossicon {
      color: #fff;
    }

    img {
      border: 2px;
    }

    p {
      margin-bottom: 30px;
      color: rgba(112, 112, 112, 1);
    }

    .modalbtn {
      display: block;
      margin: auto;
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0px;
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
  border: 1px solid #808080;
  content: "";
  background: #fff;
}

[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: #b3b3b3 #dcddde #dcddde #b3b3b3;
  content: "";
  background-image: linear-gradient(135deg, #b1b6be 0%, #fff 100%);
  background-repeat: no-repeat;
  background-position: center;
}

.file-item.filesytmmodal {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(65, 65, 65, 1) !important;
  margin-bottom: 24px;

  &:last-child {
    margin-right: auto !important;

    p {
      max-width: 250px;
      word-break: break-all;
      width: 100%;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-end;
    padding: 0px;
    width: 100%;
    flex-direction: row-reverse;

    span {
      &:last-child {
        margin-right: auto !important;
        padding-left: 0px !important;
      }
    }
  }
}

.selectbox {
  margin-bottom: 13px !important;
  margin-top: 10px;

  .ant-checkbox-wrapper {
    color: var(--sidebarLink) !important;
    align-items: center !important;
    display: flex !important;
    flex-direction: row;

    .ant-checkbox {
      margin-top: 2px;
    }
  }
}

.ant-checkbox {
  align-self: first baseline;

  &:hover {
    .ant-checkbox-inner {
      background-color: transparent !important;
      border-color: var(--pinkColor) !important;
    }
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: transparent !important;

  &::after {
    background-color: var(--pinkColor) !important;
  }
}

.ant-checkbox-inner {
  background-color: transparent !important;
}

.ant-checkbox-wrapper {
  color: var(--white) !important;
  align-items: center !important;
  display: flex !important;
  flex-direction: row-reverse;

  .ant-checkbox {
    margin-top: 2px;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: var(--pinkColor) !important;
}

.ant-checkbox-inner {
  background-color: transparent;
  border-color: var(--midGrayColor);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--pinkColor) !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  content: "";
  position: absolute;
  color: var(--pinkColor);
}

.imgdive {
  border: 1px solid transparent;
  height: 100px;
  width: 100px;
  border-radius: 30px;
  border-image-source: linear-gradient(
    136.23deg,
    #ff0083 0%,
    #0b0904 43.34%,
    #ff0083 98.5%
  );
  margin-bottom: 30px;
}

.modal_main {
  h4 {
    color: #fff;
    font-size: var(--headingTwo);
    font-weight: 700;
    margin-bottom: 26px;
  }

  .crossicon {
    color: #fff;
  }

  img {
    border: 2px;
  }

  p {
    margin-bottom: 30px;
    color: rgba(112, 112, 112, 1);
  }

  .modalbtn {
  }
}

body .introjs-arrow.top-middle {
  top: -10px !important;
}
