.header {
  padding: 27px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--borderColor2);

  @media (max-width: 767px) {
    padding: 24px 10px;
    align-items: baseline;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 490px) {
    padding: 10px;
  }

  &_left {
    display: flex;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 767px) {
      gap: 33px;
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 490px) {
      gap: 20px;
    }

    h3 {
      color: var(--sidebarLink);
      font-size: var(--headingTwo);
      font-weight: 600;
      white-space: nowrap;
    }
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: 490px) {
      gap: 5px;
    }

    .theme-toggle {
      height: 35px;
      width: 35px;
      border-radius: 10px;
      background-color: var(--toggleBtnBG);
      border: transparent;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: .1s ease-in-out all;
      svg {
        path {
          fill: var(--white);
        }
      }

      &:hover {
        transition: .1s ease-in-out all;

        background-color: var(--pinkColor);

        svg {
          path {
            fill: var(--white);
          }
        }
      }

      @media screen and (max-width: 490px) {
        width: 25px;
        height: 25px;
        border-radius: 5px;
      }
    }

    @media screen and (max-width: 767px) {
      gap: 15px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
    }

    @media screen and (max-width: 490px) {
      gap: 5px;
    }

    &_hash {
      background: var(--pinkColor);
      border-radius: 48px;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: var(--mediumParaGraph);

      @media screen and (max-width: 490px) {
        padding: 6px 5px;
        gap: 5px;
      }

      span {
        display: flex;
        align-items: center;
      }

      p {
        @media screen and (max-width: 490px) {
          font-size: 11px;
        }
      }

      svg {
        width: 14px !important;
        height: 14px !important;
        cursor: pointer !important;

        path {
          fill: var(--white) !important;
        }

        @media screen and (max-width: 490px) {
          width: 10px !important;
        }
      }
    }

    &_bellicon {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      >div:first-child {
        display: flex;
        align-items: center;
      }

      svg {
        cursor: pointer;
        width: 18px;
        height: 20px;

        path {
          fill: var(--pinkColor);
        }
      }
    }

    .customButton {
      margin: 0;
      height: 36px;
      font-size: var(--mediumParaGraph);

      @media (max-width: 450px) {
        padding: 5.5px;
      }

      &-icon {
        @media (max-width: 450px) {
          display: none;
        }
      }
    }
  }
}

.menuDropdown {
  background: var(--themeColor);
  padding: 8px 10px;
  border-radius: 48px;
  align-items: center;
  display: flex;
  gap: 10px;
  cursor: pointer;
  transition: .3s ease-in all;

  &:hover {
    transition: .3s ease-in all;

    // background: rgba(gray, .4);
    background-color: var(--pinkColor);

    .user_icon {
      svg {
        path {
          fill: var(--white);
        }
      }
    }

    svg {
      path {
        fill: var(--white);
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 4px 5px;
    gap: 5px;
  }

  .ant-space-item {
    vertical-align: baseline;
  }

  .user_icon {
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;

    @media screen and (max-width: 767px) {
      width: 18px;
      height: 18px;
    }

    svg {
      width: 22px;
      height: 22px;

      @media screen and (max-width: 767px) {
        width: 18px;
        height: 18px;
      }

      path {
        stroke: var(--bellColor);
        fill: var(--bellColor);
      }
    }
  }

  svg {

    path {
      stroke: var(--bellColor);
      fill: var(--bellColor);
    }
  }

}

.userDropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding-right: 40px !important;

      &:hover {
        // background-color:#E8F2FF !important;
        background-color: var(--hoverTable) !important;
      }
    }
  }
}

.ant-dropdown {
  ul {
    li {

      a,
      .signOutdata {
        display: flex;
        align-items: center;
        gap: 10px;


        svg {
          path {
            fill: var(--pinkColor);
          }
        }
      }
    }
  }
}

.notification {
  &-container {
    display: flex;
    align-items: baseline;
    gap: 10px;
    justify-content: space-between;

    svg {
      margin-top: 5px;
      width: 14px;
      min-width: 14px;
      height: 14px;

      path {
        fill: var(--pinkColor);
      }
    }
  }

  &:nth-child(1) {
    padding: 0 !important;
  }
}

.notificationModal {
  position: absolute;
  top: 7% !important;
  right: 12%;
  background: var(--dashcardbg);
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 450px !important;

  &_head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--midGrayBorder);
    padding-bottom: 24px;

    &_left {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        width: 14px;
        min-width: 14px;
        height: 14px;

        path {
          fill: var(--pinkColor);
        }
      }

      .title {
        font-size: var(--headingTwo);
        font-weight: 700;
      }
    }

    &_right {



      button {
        color: var(--pinkColor);
        background: transparent;
        border: none;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;

    &_inner {
      display: flex;
      gap: 10px;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      h4 {
        font-size: var(--mediumParaGraph);
        font-weight: 500;
      }

      p {
        font-size: var(--xtrasmallParagraph);
        color: var(--grayColor);


      }
    }
  }
}

.notification-wrapper {
  position: relative;
}

.comingSoon {
  font-size: var(--headingFour);
  color: var(--grayColor);
  font-weight: 600;
}

.notificationModal_content {
  display: block;
}

.bell-container {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--themeColor);
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: .1s ease-in-out all;

  @media screen and (max-width: 490px) {
    width: 25px;
    height: 25px;
    border-radius: 5px;
  }

  &:hover {
    transition: .1s ease-in-out all;

    background-color: var(--pinkColor);

    svg {
      path {
        fill: var(--white);
      }
    }
  }

  svg {
    path {
      fill: var(--bellColor);
    }
  }
}

.red-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: var(--pinkColor);
  border-radius: 50%;
  border: 3px solid var(--white);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.no-notifications {
  height: unset;
}

.notificationModal_content_inner {
  cursor: pointer;
}

.signOut {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    path {
      fill: var(--pinkColor);
    }
  }
}

.notification {
  display: none;
}