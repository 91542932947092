.ant-switch {
  min-width: 33px;
  height: 20.5px;
  // background: rgba(white, 0.3);
  background-color: var(--planBg) !important;

  &:hover {
    // background: rgba(white, 0.3) !important;
    background-color: var(--planBg) !important;
  }

  &-checked {
    // background: rgba(rgb(6, 211, 105), .99) !important;
    background: var(--pinkColor) !important;

    &:hover {
      background: var(--pinkColor) !important;

      // background: rgba(rgb(6, 211, 105), .99) !important;
    }

    .ant-switch-handle {
      inset-inline-start: calc(100% - 19px) !important;
    }
  }
}

.ant-switch-handle {
  width: 16.5px !important;
  height: 16.5px !important;
  color: var(--pinkColor);
  top: 2px !important;
  inset-inline-start: 2px !important;

  &::before {
    background-color: var(--white) !important;
    border-radius: 50% !important;
  }
}

.ant-table-column-title {
  flex: unset !important;
}

.ant-table-column-sorters {
  justify-content: unset !important;
}

.commonCardBg {
  background-color: var(--bannerColor);
  border-radius: 30px;
  border: 1px solid var(--sidebarBorder);
  padding: 30px;
  box-shadow: 6px 7px 10px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}

.showDetails {
  background: linear-gradient(90.78deg,
      rgba(111, 4, 59, 0.2) -0.64%,
      rgba(153, 0, 79, 0) 99.88%);
  padding: 30px;
  border-radius: 30px;
  border: 1px solid #422132;

  &_inner {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    &:last-child {
      margin-top: 20px;
    }

    p {
      color: var(--grayColor);
      font-size: var(--largeParaGraph);
      font-weight: 500;

      span {
        color: var(--sidebarLink);
      }
    }
  }
}

.shortContainer {
  max-width: 940px;
  width: 100%;
  margin: auto;
  padding: 15px;
}

.textAlignEnd {
  text-align: end;
}

.textAlignCenter {
  text-align: center;
}

.lineHeight20 {
  line-height: 20px;
}

.iconCenter {
  margin: auto;
  display: table;
}

.shareCard {
  margin-top: 30px;
  width: 100%;

  &_button {
    margin: auto;
    max-width: 217px !important;
  }
}

.commonSearchInput {
  max-width: 313px;
  margin: 0;
  width: 100%;
  margin-top: 0 !important;

  input {
    height: 36px !important;
  }
}

//progressbarInnercontent
.progressCommon {
  p {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--gray7D);
    padding-top: 12px;
  }
}

//commontable

.commontable {
  overflow-x: auto;
  margin: auto;
  border-radius: 16px;
  border: 1px solid var(--tableborder);
  cursor: pointer;
  scroll-behavior: smooth;

  .ant-table-wrapper {
    height: 100%;
  }

  .ant-table-header {
    overflow: unset !important;

    colgroup {
      col {
        &:last-child {
          display: none !important;
        }
      }
    }
  }

  .ant-table-content {
    max-height: 530px;
  }

  .ant-table {
    height: 100%;
    background-color: transparent;
    color: var(--sidebarLink);

    .ant-table-body {
      // overflow: visible !important;
      scrollbar-width: thin;
    }

    .ant-table-tbody {
      overflow-y: auto !important;
      height: calc(100% - 70px);
    }

    thead.ant-table-thead {
      height: 70px;

      @media (max-width: 767px) {
        height: 50px;
      }

      tr {
        th.ant-table-cell {
          background: var(--tableHead) !important;
          padding-bottom: 10px;
          font-size: var(--largeParaGraph16);
          color: var(--tableHeadText);
          font-weight: 400;
          position: sticky;
          top: 0;
          z-index: 9;

          &::before {
            display: none;
          }

          svg {
            margin-left: 8px;
          }
        }
      }
    }

    .ant-table-thead>tr>th {
      background-color: transparent;
      font-size: var(--largeParaGraph);
      color: var(--white);
      border-bottom: 1px solid var(--borderColor2);
      white-space: nowrap;
      font-weight: 600;

      &:first-child {
        padding-left: 20px;
      }

      &::before {
        width: 0rem;
      }

      &:last-child {
        text-align: left;
      }

      span {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: none;
    }

    .ant-table-column-sorter-inner {
      gap: 2px !important;

      .anticon {
        color: var(--grayColor) !important;

        svg {
          margin-left: 0 !important;
        }
      }

      .active {
        color: var(--pinkColor) !important;
      }
    }

    .ant-table-column-has-sorters,
    .ant-table-column-sort {
      // background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    tbody.ant-table-tbody {
      tr.ant-table-placeholder:hover>td {
        background: transparent !important;
      }

      .ant-table-placeholder {
        background: transparent !important;

        .ant-table-cell {
          border-bottom: 1px solid var(--borderColor2) !important;
        }

        .ant-empty-description {
          color: var(--sidebarLink) !important;
        }

        &:hover {
          background: transparent !important;
        }
      }

      tr.ant-table-row {
        text-transform: capitalize;

        &:hover {
          .ant-table-column-sort {
            background-color: var(--hoverTable) !important;
          }
        }

        &.ant-table-row-level-0 {
          td.ant-table-cell {
            font-size: var(--largeParaGraph16);
            font-weight: 400;
            line-height: normal;
            background-color: var(--tableData);

            &.ant-table-cell-row-hover {
              background-color: var(--hoverTable);
            }

            &:first-child {
              padding-left: 20px;

              .ellipsis {
                span {
                  display: flex;
                  align-items: center;
                }
              }

              img,
              svg {
                width: 18px !important;
                height: 18px !important;
              }
            }

            &:last-child {
              text-align: left;
            }
          }
        }

        &:last-child td {
          border-bottom: 0;
        }

        td {
          border-bottom: 1px solid var(--borderColor2);
          white-space: nowrap;
          padding: 20px 16px;

          @media (max-width: 767px) {
            padding: 15px;
          }
        }
      }
    }
  }

  .ant-table-cell-scrollbar {
    display: none;
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
  }

  .ant-empty {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 991px) {
      min-height: 200px;
    }
  }
}

.rejected {
  color: var(--midGrayColor);
}

/////antd Tabs////////////////

.ant-tabs {
  &-ink-bar {
    background: var(--welcomeText) !important;
  }

  &-nav::before {
    display: none;
  }

  &-tab {
    color: var(--grayColor) !important;
    font-size: var(--largeParaGraph16) !important;
    font-weight: 400;
    line-height: 28.51px;

    &-active {
      .ant-tabs-tab-btn {
        color: var(--sidebarLink) !important;
      }
    }
  }
}

.threeDot {
  padding: 0 10px;

  svg {
    path {
      fill: var(--sidebarLink);
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--pinkColor);
      }
    }
  }
}

.ant-tabs-tab-btn {
  &:active {
    color: var(--whiteFFF) !important;
  }
}

.ant-tabs-nav {
  justify-content: space-between !important;
}

.ant-tabs-nav .ant-tabs-nav-more {
  color: var(--pinkColor) !important;
  cursor: pointer;
}

.commonTab {
  .ant-tabs-nav {
    gap: 30px;
    flex-flow: wrap;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav-wrap {
    flex: unset !important;
  }

  .ant-tabs-extra-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 510px) {
      flex-flow: wrap;
      width: 100%;
    }
  }

  .ant-tabs-tab {
    padding: 8px 20px !important;
    margin: unset !important;
    transition: transform 0.3s ease-in-out;
    border-radius: 14px 14px 0 0;
  }

  .ant-tabs-tab-active {
    // background: #ff008423 !important;
    background: var(--tableHead) !important;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid var(--midGrayBorder);
    transform: unset !important;
  }
}

//////// Date Picker////////////////
.flatpickr-wrapper {
  margin-right: 10px;
}

.flatpickr-calendar.arrowTop::after {
  border-bottom-color: #282626 !important;
}

.flatpickr-calendar {
  position: absolute !important;
  z-index: 9999;
  top: 50%;
  left: 0% !important;

  @media (max-width: 1199px) {
    left: -88px !important;
  }

  @media (max-width: 517px) {
    left: 0 !important;
  }
}

.flatpickr-month,
.flatpickr-monthDropdown-months {
  background: #282626 !important;
}

.flatpickr-monthDropdown-month {
  background-color: #282626 !important;
}

.flatpickr-weekdays {
  background: #282626 !important;

  span.flatpickr-weekday {
    background: #282626;
    color: var(--whiteFFF) !important;
  }
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background: var(--pinkColor) !important;
}

.flatpickr-day {
  color: var(--whiteFFF) !important;
}

.flatpickr-day.inRange,
.flatpickr-day.selected,
.flatpickr-day.selected.endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange:not(:nth-child(7n + 1)) {
  background: #e2e2e23e !important;
  -webkit-box-shadow:
    -5px 0 0 #e2e2e23e,
    5px 0 0 #e2e2e23e !important;
  box-shadow:
    -5px 0 0 #e2e2e23e,
    5px 0 0 #e2e2e23e !important;
  border: none;
}

.flatpickr-innerContainer {
  background: var(--SideBarBg) !important;
}

.flatpickr-input {
  background: transparent;
  padding: 5px 10px;
  border-radius: 48px;
  border: 1px solid var(--pinkColor);
  color: var(--whiteFFF) !important;
  text-align: center;

  &:focus-visible,
  .active {
    outline: unset !important;
  }
}

.ant-picker {
  background: transparent !important;
  border: 1px solid var(--pinkColor) !important;
  border-radius: 48px;
}

.ant-picker {
  color: var(--whiteFFF);
  max-width: 218px !important;

  .ant-picker-suffix {
    color: var(--whiteFFF);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--pinkColor) !important;
}

.ant-picker-active-bar {
  background: var(--pinkColor) !important;
}

.ant-picker-separator {
  color: var(--pinkColor) !important;
}

.ant-picker-input {
  flex-direction: row-reverse;
  gap: 9px;
  justify-content: space-between !important;

  &::placeholder {
    color: var(--sidebarLink) !important;
  }
}

.ant-picker:hover .ant-picker-suffix:not(:last-child) {
  opacity: 1 !important;
}

.ant-dropdown-menu {
  background: #282626cc !important;

  &-title-content {
    font-size: var(--mediumParaGraph);
  }

  &-item {
    color: var(--sidebarLink) !important;

    &:hover {
      background-color: var(--hoverTable) !important;
    }
  }
}

.ant-picker-panel-container {
  background: transparent !important;
  border-radius: 30px !important;
}

.ant-picker-body {
  background: var(--SideBarBg);
}

.ant-picker-panel-layout {
  background: #0c0c0c !important;

  .ant-picker-header {
    color: var(--whiteFFF) !important;
    border-color: var(--midGrayColor) !important;

    button {
      color: var(--whiteFFF);

      &:hover {
        color: var(--pinkColor);
      }
    }
  }

  .ant-picker-content th,
  .ant-picker-cell-in-view .ant-picker-cell-inner {
    color: var(--whiteFFF) !important;
  }
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--pinkColor) !important;
}

:where(.css-dev-only-do-not-override-d2lrxs) .ant-picker-cell {
  color: rgba(255, 255, 255, 0.25) !important;
}

.ant-picker-now-btn {
  color: var(--pinkColor);
}

////////////////////////Drawer Styles ///////////////////////////
.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-content-wrapper {
  max-width: 300px !important;
  width: 100% !important;
  background: transparent !important;
}

.ant-drawer-header {
  display: none !important;
}

.flatpickr-day:hover {
  background-color: var(--buttonBg) !important;
}

.flatpickr-current-month {
  .flatpickr-monthDropdown-month {
    background-color: var(--dashcardbg) !important;
  }
}

.flatpickr-months {
  background: #282626;
}

.custom-breadcrumb {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.back-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--sidebarLink);

  p {
    display: flex;
    align-items: center;
    background-color: var(--pinkColor);
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 20px;
    color: #fff;
    margin-right: 8px;
    width: 100%;

    svg {
      margin-right: 6px;
    }
  }
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .breadcrumb-text {
    color: var(--mainGrayText) !important;
    font-size: var(--largeParaGraph);
    font-weight: 500;
    cursor: pointer;
    text-transform: capitalize;
  }
}

.breadcrumb-separator {
  svg {
    path {
      fill: var(--mainGrayText) !important;
    }
  }

  display: flex;
  align-items: center;
  margin: 0 10px;
}

.ant-tooltip {
  &-arrow {
    &::after {
      background: var(--tooltipBack) !important;
    }
  }

  &-inner {
    background-color: var(--tooltipBack) !important;
    // background-color: var(--tooltipBg) !important;
    color: var(--sidebarLink) !important;
    font-size: var(--largeParaGraph16);
    font-weight: 500;
  }
}

.ant-skeleton {
  padding: 0 20px;

  .ant-skeleton-active {
    display: flex !important;

    span {
      margin: 0 auto !important;
    }
  }
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-title {
  background: linear-gradient(90deg,
      rgba(134, 128, 132, 0.445) 25%,
      rgba(54, 49, 53, 0.247) 37%,
      rgba(114, 109, 112, 0.445) 63%);
  background-size: auto;
  background-size: 400% 100%;
  animation-name: ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph>li {
  background: linear-gradient(90deg,
      rgba(134, 128, 132, 0.445) 25%,
      rgba(54, 49, 53, 0.247) 37%,
      rgba(114, 109, 112, 0.445) 63%);
  background-size: auto;
  background-size: 400% 100%;
  animation-name: ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  width: 145px;
}

.tableSkeleton {
  border-radius: 20px;
  border: 1px solid var(--borderColor2);
  padding: 20px !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 300px;
  width: 100%;
  text-overflow: ellipsis;
}

.toolbar-wrapper {
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

.disableBtn {
  cursor: not-allowed !important;
  opacity: 0.6;
  background-color: var(--midGrayColor) !important;

  p {
    color: var(--white) !important;
  }
}

.commonSelect {
  width: 100%;
  margin-top: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;

  label {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    display: inline-block;
    margin-bottom: 12px;
    color: var(--midGrayColor);

    span {
      color: var(--pinkColor);
    }
  }
}

.dFlex {
  display: flex;
  align-items: center !important;

  svg {
    path {
      fill: var(--pinkColor);
    }
  }
}

.comingSoon {
  font-size: var(--headingThree);
  font-weight: bold;
  color: var(--mainGrayText);
  margin: 60px 0;
  text-align: center;
}

.containerModal {
  .modal-content-paragraph {
    display: none !important;
  }

  .modal-header {
    border-bottom: 1px solid var(--midGrayBorder);
  }

  .upgradePlan_storage {
    margin-top: 0 !important;
  }

  &_bottom {
    >div {
      display: flex;
      justify-content: space-between;
      align-items: first baseline;
      gap: 10px;
      margin-top: 15px;

      &:first-child {
        margin-top: 0 !important;
      }

      @media screen and (max-width: 400px) {
        margin-top: 10px;
      }

      h4 {
        font-size: var(--largeParaGraph16);
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: right;
      }

      p {
        white-space: nowrap;
        color: var(--grayColor);
        font-size: var(--mediumParaGraph);
        font-weight: 400;
      }

      h4 {
        color: var(--sidebarLink);
      }
    }
  }
}

.toastOptions {
  // background: linear-gradient(90deg,
  //     rgb(3, 3, 3) 0%,
  //     rgba(59, 22, 41, 0.473) 0%,
  //     rgba(129, 1, 67, 0.719)) !important;
  background: var(--toastColor) !important;
  color: var(--sidebarLink) !important;
  min-width: 0;
  width: unset !important;
  max-width: 800px !important;
  word-wrap: break-word;
}

.uplodDetailModal {
  .modal-content-paragraph {
    display: none !important;
  }

  .modal-header {
    border-bottom: 1px solid var(--midGrayBorder);
  }

  .upgradePlan_storage {
    max-width: 375px;
    width: 100%;
    margin-top: 0 !important;
  }

  &_bottom {
    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      @media screen and (max-width: 400px) {
        margin-top: 10px;
      }

      &:first-child {
        margin-top: 5px;
      }

      .ellipsis {
        max-width: 275px;
      }

      span {
        cursor: pointer;
      }

      h5 {
        font-size: var(--largeParaGraph16);
        font-weight: 600;

        &:last-child {
          color: var(--pinkColor);
          cursor: pointer;
        }
      }

      h4 {
        font-size: var(--largeParaGraph);
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: right;
      }

      p {
        color: var(--grayColor);
        font-size: var(--largeParaGraph16);
        font-weight: 400;
      }
    }
  }
}

.editNameModal {
  .customInput .error {
    bottom: -25px !important;
  }

  .customButton {
    margin-top: 40px !important;
  }
}

.newContainerModal {
  .customButton {
    p {
      width: 70px;
    }
  }

  &_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-flow: wrap;
  }

  .customButton {
    margin-top: 30px !important;
  }

  .customInput {
    margin: 0 !important;
  }

  .customInput .error {
    bottom: -28px !important;
    line-height: 20px !important;
  }

  .modal-content-paragraph {
    display: none !important;
  }
}

.options {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: right;

  button {
    background: var(--pinkColor);
    border: none;
    cursor: pointer;
    border-radius: 20px;
    color: var(--white);
    padding: 6px 20px;
    font-size: var(--mediumParaGraph);

    &:last-child {
      background: #282626;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.introjs-tooltip {
  box-shadow: none !important;
  max-width: 100% !important;
  width: 100% !important;
  text-align: center;
  background-color: transparent !important;
  padding: 20px !important;
  border-radius: 44px !important;
}

.introjs-tooltip .introjs-arrow {
  border-color: red;
  border-width: 10px;
  content: "";
  background-image: url(./Assets/images/ArrowLong.png);
  height: 125px;
  background-repeat: no-repeat;
  border: none !important;
  left: 50% !important;
  top: -18px !important;
  width: 59px;
  height: 76px;
  background-repeat: no-repeat;
  right: 0;

  @media screen and (max-width: 768px) {
    left: 26% !important;
  }
}

.create-container-tooltip {
  .introjs-arrow {
    @media (max-width: 1584px) {
      transform: scaleX(-1) rotate(20deg);
      left: 30% !important;
    }

    @media screen and (max-width: 768px) {
      left: 40% !important;
    }
  }
}

.introjs-bullets {
  display: none;
}

.introjs-helperNumberLayer {
  display: none;
}

.introjs-tooltipbuttons {
  border-top: none !important;
  justify-content: center;
  display: flex;
  gap: 20px;
}

.introjs-helperLayer {
  border-radius: 30px !important;
}

.introjs-button {
  font-size: var(--largeParaGraph) !important;
  font-family: "Aeonik Pro", sans-serif !important;
  text-shadow: none !important;
  border-radius: 48px !important;
  outline: none !important;
  max-width: 123px !important;
  width: 100%;
  text-align: center;
  border: 1px solid;
}

.introjs-tooltiptext {
  font-size: var(--largeParaGraph);
  font-weight: 400;
  padding: 20px 0 !important;
}

.introjs-nextbutton {
  background-color: var(--pinkColor) !important;
  color: var(--white) !important;
  border-color: transparent !important;

  &:hover {
    opacity: 0.8;
  }
}

.introjs-overlay {
  background-color: transparent !important;
  opacity: 1 !important;
  backdrop-filter: blur(3px) !important;
}

.introjs-skipbutton {
  color: var(--white) !important;
  font-size: var(--headingThree);
  background: #00000081 !important;
  border-radius: 14px;
  line-height: unset !important;
  font-weight: 400 !important;
  font-size: 33px !important;
}

.introjs-prevbutton {
  border-color: var(--pinkColor) !important;
  background-color: transparent !important;
  color: var(--pinkColor) !important;

  &:hover {
    background-color: var(--pinkColor) !important;
    color: var(--white) !important;
    border-color: transparent !important;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
}

.ant-image-preview {
  background: #000000c9;
}

.ant-image-preview-footer {
  background: var(--SideBarBg);
  border: 1px solid var(--pinkColor);
  border-radius: 30px;

  .ant-space {
    align-items: center;
  }

  .ant-space-item {
    display: flex;
    align-items: center;
  }

  .anticon {
    @media screen and (max-width: 767px) {
      padding: 5px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &:hover {
      opacity: 1;

      svg {
        fill: var(--pinkColor);
      }
    }
  }
}

.ant-image-preview {
  background-color: rgba(0, 0, 0, 0.821) !important;
}

.uploadComp {
  background: var(--dashcardbg);
  border-radius: 30px;
  padding: 20px;
  position: absolute;
  z-index: 999;
  right: 10px;
  bottom: 10px;

  .uplodDetailModal_bottom {
    max-height: 219px;
    overflow: scroll;

    >div {
      gap: 0 !important;

      >div:last-child {
        margin-right: 20px;
      }
    }
  }

  &_close {
    cursor: pointer;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.shareWith {
  margin-top: 20px;

  h4 {
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    color: var(--sidebarLink);
    margin-bottom: 12px;
  }

  &_container {
    background: var(--shareFolder);
    padding: 20px;
    border-radius: 25px;
    max-height: 210px;
    overflow-y: scroll;

    @media screen and (max-width: 420px) {
      padding: 15px;
    }

    &_inner {
      padding: 16px 0;
      display: flex;
      align-items: center;
      flex-flow: wrap;
      gap: 10px;
      justify-content: space-between;
      border-bottom: 1px solid var(--borderColor2);
      svg {
        width: 20px;
        height: 20px;
        path{
          fill:var(--pinkColor)
        }
      }


      .customButton {
        p {
          font-size: var(--mediumParaGraph) !important;
          font-weight: 500 !important;

          span {
            word-break: break-all;
          }
        }
      }

      &:first-child {
        padding-top: 0 !important;
      }

      &:last-child {
        padding-bottom: 0 !important;
        border-bottom: unset;
      }

      >div:first-child {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          width: 20px;
          height: 20px;
          path{
            fill:var(--pinkColor)
          }
        }
      }
    }
  }

  &_btn {
    margin: 0 !important;
  }
}

.cursor-pointer {
  cursor: pointer;

  svg {
    path {
      fill: var(--pinkColor);
    }
  }
}

.cursor-notAllowed {
  cursor: not-allowed !important;
}

.tooltip-custom {
  z-index: 1000;
}

.introjs-tooltipReferenceLayer {
  visibility: visible !important;
}

.errorPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.ant-table-row {
  text-transform: none !important;
}

.leftbutton {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.m_top20 {
  margin-top: 20px !important;
}