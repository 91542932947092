.accessKey {
  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  &_bottom {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  &_card {
    max-width: 371px;
    width: 100%;
    p {
      margin: 20px 0;
      color: var(--midGrayColor);
      font-size: var(--largeParaGraph16);
    }
    &_top {
      border-bottom: 1px solid var(--borderColor2);
      > div,
      > div > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        svg {
          cursor: pointer;
          path {
            fill: var(--pinkColor);
          }
        }
        h4 {
          font-size: var(--headingThree);
          font-weight: 600;
        }
      }
    }
    &_bottom {
      h5 {
        font-size: var(--largeParaGraph);
        font-weight: 600;
      }
    }
  }
}
