.publicShare {
  .reportMediaBtn {
    background: transparent;
    border: transparent;
    padding: 0;
    color: var(--pinkColor);
    text-decoration: underline;
    margin-left: auto;
    display: flex;
    margin-bottom: 20px;
    font-size: var(--largeParaGraph);
  }

  &_imageSec {
    margin: 20px auto 60px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 850px;
      width: 100%;
      object-fit: contain;
    }
  }
}
