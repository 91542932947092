.create-api {
  .modal-header {
    .title {
      font-weight: 700;
      font-size: var(--headingTwo);
    }
  }

  .ant-modal-content {
    .new_api_input {
      .customInput-rightBtn {
        background-color: transparent;
      }
    }
  }

  .new_modal_input {
    display: flex;

    align-items: center;

    .new_api_input {
      width: 100%;

      .customInput-rightIcon {
        width: 50px;
        svg {
          path {
            fill: #2f2f2f;
          }
        }
      }
    }

    .copy_btn {
      margin: 20px 0 0 20px;
    }
  }

  .secret_key_box {
    margin-bottom: 20px;
    background-color: var(--ApiBG);
    border-radius: 20px;
    margin-top: 20px;
    padding: 20px;

    @media (max-width: 1399px) {
      margin-top: 20px;
      padding: 16px;
    }

    @media (max-width: 575px) {
      margin-top: 10px;
      padding: 10px;
    }

    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        margin-right: 10px;
        height: 23px;

        @media (max-width: 575px) {
          margin-right: 6px;
        }
      }

      color: var(--pinkColor);
    }

    p {
      font-size: var(--c);
    }
  }

  .note {
    margin-top: 20px;

    h2 {
      font-size: var(--headingTwo);
      font-weight: 700;
    }

    p {
      color: var(--grayColor);
      font-size: var(--largeParaGraph16);
    }

    @media (max-width: 1399px) {
      margin-top: 16px;
    }

    @media (max-width: 575px) {
      margin-top: 10px;
    }

    .confirm_btn {
      max-width: 300px !important;
      width: 100% !important;
      margin: 20px auto 0;
    }
  }
}
.copy_btn {
  cursor: pointer;
}
