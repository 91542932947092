.slider-container {
  display: flex;
  width: 100%;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding: 20px 0;
  scroll-behavior: smooth;
  transition: transform 0.3s ease-in-out;
}

.slider-item {
  min-width: 371px;
  transition: transform 0.3s ease-in-out;

  .uploadText {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 16px;

    span {
      font-size: var(--largeParaGraph16);
      font-weight: 500;
      color: var(--sidebarLink) !important;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    svg {
      cursor: pointer;
    }
  }

  h2 {
    font-size: var(--headingThree);
    font-weight: 600;
    color: var(--grayColor);
    padding-bottom: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &_timeAgo {
    font-size: var(--largeParaGraph16);
    font-weight: 400;
    color: var(--grayColor) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575px) {
      padding-top: 16px;
    }

    svg {
      @media screen and (max-width: 767px) {
        width: 24px;
      }
    }
  }
}

.arrow-button {
  border: none;
  margin: 10px;
  cursor: pointer;
  user-select: none;
  background: transparent;
  // box-shadow: 0 0 20px 27px #000;
  height: 200px;
  transition:
    background-color 0.2s ease,
    transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.back-arrow-button {
  rotate: -180deg;
}

.arrow-button:disabled {
  background-color: #464646ea;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.slider-wrapper {
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

.no-logs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-bottom: 20px;

    @media (max-width: 575px) {
      max-width: 80px;
    }
    path {
      fill: var(--pinkColor);
    }
  }
}
