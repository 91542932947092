.custom-breadcrumb {
  display: flex;
  align-items: center;
}

.breadcrumb-item {
  cursor: pointer;
}

.breadcrumb-item.clickable {
  cursor: pointer;
}

.breadcrumb-first {
  font-size: var(--largeParaGraph);
  color: gray !important;
}

.breadcrumb-last {
  font-size: var(--largeParaGraph) !important;
  color: var(--pinkColor);
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
