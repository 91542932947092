.loginBoard {
  align-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;

  &_left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 850px;
    width: 90%;
    min-height: 100vh;
    background-color: #F5F9FF;
    // background: url(../../../Assets/images/LoginBG.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% cover;

    @media (max-width: 991px) {
      display: none;
    }

    img {
      max-width: 500px;
      width: 100%;
      transform: translatey(0px);
      animation: float 4s ease-in-out infinite;

      @media (max-width: 1390px) {
        max-width: 300px;
      }


      @media (max-width: 575px) {
        max-width: 161px;
      }
    }
  }

  &_right {
    height: 100%;
    flex: 1;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: var(--largeParaGraph16);
    font-weight: 500;
    gap: 20px;
    backdrop-filter: blur(8px);

    @media (max-width: 991px) {
      max-width: 812px;
      width: 100%;
      padding: 10px 20px;
    }

    &_content {
      max-width: 602px;
      margin: auto;
      width: 100%;
      height: 100%;

      @media (max-width: 991px) {
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 20px 15px;
        border-radius: 30px;
        box-shadow: inset var(--boxShadow2);
        border: 1px solid var(--btndarkred);
      }

      p {
        color: #000;
        font-size: var(--largeParaGraph);
      }
    }

    &_header {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;

      @media (max-width: 991px) {
        justify-content: center;
      }

      img {
        max-width: 116px;
        width: 100%;
      }
    }

    &_heading {
      font-size: var(--largSize40);
      font-weight: 900;
      line-height: 65px;
      color: var(--sidebarLink);
      margin: 0;

      @media screen and (max-width: 767px) {
        line-height: 37px;
      }
    }

    h2 {
      font-size: var(--largSize77);
      font-weight: 900;
      line-height: 74px;
      margin-top: 10px;
      color: var(--sidebarLink);
      white-space: nowrap;

      span {
        color: var(--pinkColor);
      }

      @media screen and (max-width: 991px) {
        line-height: 64px;
      }

      @media screen and (max-width: 767px) {
        line-height: 54px;
      }
    }

    &_paragraph {
      font-size: var(--largeParaGraph16) !important;
      font-weight: 400;
      color: var(--midGrayColor);
    }
  }
}