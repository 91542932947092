.share-container {
  &_top {
    margin-bottom: 30px;
  }
  .publicSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    gap: 10px;
    &_left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      height: 50px;
      background: var(--inputBg);
      border-radius: 48px;
      padding: 15px;
      width: 100%;
      p {
        font-size: var(--largeParaGraph);
        font-weight: 400;
        text-overflow: ellipsis;
        max-width: 246px;
        width: 100%;
        white-space: nowrap;
        word-wrap: nowrap;
        overflow: hidden;
      }
    }
    .customButton {
      max-width: 122px;
      gap: 20px;
      height: 50px !important;
      margin-top: 0 !important;
      @media (max-width: 767px) {
        height: 40px !important;
      }
    }
  }
  .customButton {
    max-width: 100%;
  }
}

.shortWidthModal {
  max-width: 544px !important;
}
.share-container2 {
  max-width: 706px !important;
  .modal-content-paragraph {
    margin: 0 !important;
  }

  .publicSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 9px;
    width: 100%;
    @media screen and (max-width: 590px) {
      flex-flow: wrap;
    }
    &_left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      height: 50px;
      background: transparent;
      border: 1px solid #ff0083;
      border-radius: 48px;
      padding: 15px 20px;
      width: 100%;
      p {
        font-size: var(--largeParaGraph);
        font-weight: 400;
        text-overflow: ellipsis;
        max-width: 246px;
        width: 100%;
        white-space: nowrap;
        word-wrap: nowrap;
        overflow: hidden;
      }
    }
    .customButton {
      max-width: 122px;
      gap: 20px;
      width: 100%;
      height: 50px !important;
      margin-top: 0 !important;
      p {
        width: 122px;
      }
      @media (max-width: 767px) {
        height: 40px !important;
      }
    }
  }
  &_top2 {
    display: flex;
    align-items: end;
    gap: 20px;
    @media screen and (max-width: 590px) {
      flex-flow: wrap;
    }
    .customButton {
      max-width: 158px !important;
      height: 50px;
    }
  }
  .customInput {
    width: 100% !important;
    label {
      position: unset;
    }
    &-rightIcon {
      right: 0px;
    }
    input {
      border: none !important;
      background: transparent !important;
      border: 1px solid #ff0083 !important;
      border-radius: 25px !important;
      outline: none !important;
      padding: 15px 20px !important;
      padding-right: 50px !important;

      &:focus,
      &:hover {
        padding-left: 20px !important;
        // outline: none !important;
      }
      &::placeholder {
        color: var(--sidebarLink) !important;
      }
    }
  }
}
