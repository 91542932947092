.accountCard {
  background: var(--planBg);
  padding: 30px;
  border-radius: 30px;
  box-shadow: 5px 8px 10px rgba($color: #000, $alpha: .07);
  max-width: 393px;
  width: 100%;
  transform: scale(0.9);
  transition: all 0.5s ease-in-out;

  // max-width: min-content;
  &.dashboard_account_card {
    @media (max-width: 1699px) {
      max-width: calc(100% / 3);
      flex-shrink: 0;
      overflow: hidden;
    }

    @media (max-width: 1399px) {
      max-width: 393px;
    }
  }

  .ant-skeleton-button {
    margin-left: 80px;
  }

  // &.hover {
  &.activePlan {
    // animation: scale 0.5s forwards;
    background: var(--planBg1);
    // box-shadow: var(--boxShadow2);
    box-shadow: 5px 8px 10px rgba($color: #000, $alpha: .07);
  }

  // }

  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(610px - 10px);
    height: 100%;

    .amountContent {
      color: var(--grayColor);
      font-weight: 400;
      flex-wrap: wrap;
      font-size: 22px;

      @media (max-width:1599px) {
        font-size: 18px;
      }

      @media (max-width:1499px) {
        font-size: 17px;
      }

      @media (max-width:425px) {
        font-size: 16px;
      }
  

      .value {
        word-break: break-all;
        white-space: normal;
      }

      &_symbol {
        margin-left: 10px;
      }
    }
  }

  &_tittle {
    display: flex;
    align-items: center;
    gap: 10px;

    h4 {
      text-transform: capitalize;
    }

    svg {
      @media screen and (max-width: 676px) {
        width: 20px;
      }
    }
  }

  h2 {
    font-weight: 900;
    margin-top: 20px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    gap: 4px;
    font-size: 24px;


    @media (max-width:1599px) {
      font-size: 20px;
    }

    @media (max-width:1499px) {
      font-size: 19px;
    }

    @media (max-width:425px) {
      font-size: 18px;
    }

    p {
      display: flex;
      align-items: center;
      color: var(--grayColor);
      font-weight: 400;
      font-size: var(--heading26);

      img {
        width: 26px;
        margin: 0 10px;
      }

      span {
        font-size: 20px;
        margin-left: 5px;
      }
    }

    span {
      color: var(--grayColor);
      font-weight: 400;
    }
  }

  h3 {
    font-size: var(--largeParaGraph16);
    color: var(--gray7D);
    font-weight: 400;
    margin-top: 15px;
    line-height: 25px;
  }

  h4 {
    font-size: var(--heading26);
    font-weight: 500;
    color: var(--pinkColor);
  }

  h5 {
    font-size: var(--headingFour);
    font-weight: 400;
    color: var(--pinkColor);
  }

  &_border {
    height: 1px;
    background: var(--grayColor);
    margin: 25px 0 20px 0;
  }

  &_includes {
    ul {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 0;
      margin-top: 10px !important;

      li {
        display: flex;
        align-items: center;
        gap: 14px;

        svg {
          path {
            fill: var(--sidebarLink);
          }
        }

        p {
          font-size: var(--largeParaGraph16);
          color: var(--gray7D);
        }
      }
    }
  }

  &_btn {
    display: flex;
    justify-content: center;

    .customButton {
      margin-top: 30px !important;
      max-width: 213px !important;
      height: 43px;

      @media (max-width: 1399px) {
        height: 40px;
        margin-top: 20px !important;
      }

      max-width: 100%;

      p {
        font-weight: 500;
        font-size: 16px !important;

        @media (max-width: 1399px) {
          font-size: 16px !important;
        }
      }
    }
  }
}