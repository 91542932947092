.common-modal {
  max-width: 560px;
  width: 100% !important;
  color: var(--sidebarLink);

  &:nth-child(1) {
    padding: 0 15px;
  }

  .ant-modal-content {
    background-color: var(--mainBackGround);
    border-radius: 30px;
    padding: 30px !important;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: 200px;
      height: 200px;
      background-image: url('../../Assets/images/modal-bg.png');
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      opacity: 0.1;
    }
    .ant-modal-body{
      z-index: 1;
      position: relative;
    }
    @media screen and (max-width: 676px) {
      padding: 20px !important;
    }
  }

  .ant-modal-close {

    svg {
      path {
        fill: var(--sidebarLink);
      }
    }


    color: var(--whiteFFF);

    &:hover {
      color: var(--pinkColor);
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    line-height: 19px;

    .icon {
      margin-right: 10px;
      font-size: 24px;
      display: flex;
      align-items: center;
      color: var(--pinkColor);
    }

    .title {
      font-size: var(--largeParaGraph16);
      font-weight: 600;
    }
  }

  .modal-content {
    padding-top: 15px;

    &-paragraph {
      color: var(--tableHeadText);
      font-size: var(--xtrasmallParagraph);
      margin-bottom: 30px;
    }

    &-botton {
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }
}

.ant-modal-mask {
  background-color: rgba(74, 64, 74, 0.45) !important;
}

.commonViewModal {
  max-width: 80% !important;
}