.reportModal {
  max-width: 600px !important;

  .ant-modal-body {
    .modal-header {
      display: none;
    }
  }

  .modal-content-paragraph {
    display: none;
  }

  &_inner {
    &_inputs {
      display: flex;
      gap: 20px;

      @media screen and (max-width: 676px) {
        flex-flow: wrap;
      }

      .customInput {
        width: 100%;
      }
    }

    h4 {
      font-size: var(--headingTwo);
      font-weight: 700;
      color: var(--sidebarLink);
    }

    p {
      font-size: var(--largeParaGraph16);
      font-weight: 400;
      color: var(--grayColor);
    }

    &_button {
      margin: auto;
      margin-top: 30px;

      p {
        width: 50px;
      }
    }
  }
}

.bottom_action {
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h3 {
    padding: 10px 25px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    max-width: 220px;
    width: 100%;
    height: 50px;
    background-color: #171717;

    span {
      margin-left: 10px;
      cursor: pointer;

      svg {
        height: 17px;
        user-select: none;
      }
    }
  }

  .customButton {
    margin-top: 0;
  }
}

.margin-field {
  margin-top: 40px;
}