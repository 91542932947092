.upgradePlan {
  .customButton {
    font-weight: 500 !important;
  }

  &_free {
    font-size: var(--mediumParaGraph);
    color: var(--gray7D);
  }

  &_storage {
    width: 100%;
    margin-top: 20px;

    h4 {
      font-size: var(--largeParaGraph);
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: var(--sidebarLink);
      }
    }

    p {
      color: var(--storageClr);
      font-size: var(--largeParaGraph16);
    }
  }

  .customButton {
    margin-top: 39px;
  }
}

.ant-progress-inner {
  background-color: var(--progressInner) !important;
  height: 6 px !important;

  .ant-progress-bg {
    &::after {
      background-color: var(--progressBar);
    }
  }
}

.ant-progress-line {
  width: 65%;
}


.activeUpgrade {
  box-shadow: 0px 0px 15px 0px var(--buttonBg);
  transform: scale(1.1);
}