.customInput {
  margin-top: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  &-rightBtn,
  &-rightIcon {
    position: absolute;
    right: 8px;
    bottom: 8px;
    max-width: 119px;
    height: 34px;
    padding: 0 24px;
    font-size: var(--mediumParaGraph);
    border-radius: 23px;
    background: var(--pinkColor);
    border: none;
    color: var(--whiteFFF);
    white-space: nowrap;
    cursor: pointer;
  }

  &-rightIcon {
    max-width: unset;
    background: transparent;
  }
  label {
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    display: inline-block;
    margin-bottom: 9px;
    color: var(--sidebarLink);
    line-height: 20px;
    max-width: min-content;
    background: var(--lableBackground);
    white-space: nowrap;
    position: absolute;
    left: 20px;
    top: -10px;
    z-index: 1;
    padding: 0 4px;
    border-radius: 15px;

    span {
      color: var(--pinkColor);
    }
  }
  input {
    &:disabled {
      outline: unset;
      opacity: 50%;
    }
    &:is(:focus, :focus-visible, :focus-within) {
      border: 0;
    }
  }
  .clear-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      fill: var(--sidebarLink);
    }
  }
  textarea {
    background: transparent;
    font-size: var(--mediumParaGraph);
    color: var(--sidebarLink);
    border: none;
    outline: unset;
    border-radius: 25px;
    padding: 11px 25px;
    min-height: 100px;
    resize: none;
    height: 100px;
    overflow: hidden;
    border: 1px solid var(--btndarkred) !important;
    &::placeholder {
      color: var(--sidebarLink);
      font-size: var(--mediumParaGraph);
      font-weight: 400;
    }
  }
  input {
    text-decoration: none;
    outline: unset;
    background: transparent;
    font-size: var(--mediumParaGraph);
    color: var(--sidebarLink);
    border: 1px solid var(--btndarkred) !important;
    font-weight: 500;
    border-radius: 25px;
    padding: 11px 25px;
    width: 100%;
    height: 50px;
    border: none;
    text-align: left;
    transition: all 0.4s ease-in-out;

    &::placeholder {
      color: var(--sidebarLink);
      font-size: var(--mediumParaGraph);
      font-weight: 400;
    }
    &:focus,
    &:hover {
      padding-left: 40px;
      outline: 1.5px solid var(--pinkColor);
    }
  }
  .error {
    color: var(--error);
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 4px;
    font-size: var(--smallParagraph) !important;
    bottom: -30px;
    height: 28px;
  }
  &-password {
    position: relative;
    &_wrapper {
      position: relative;
      input {
        padding-right: 50px;
        width: 100%;
        max-width: 100%;
      }
      button {
        position: absolute;
        right: 12px;
        border: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        padding: 0;
        cursor: pointer;
      }
    }
  }
  &-inputError {
    input {
      outline: 1px solid var(--error) !important;
      &:hover {
        outline: 1px solid var(--error) !important;
      }
    }
  }
}

input:-webkit-autofill {
  background-color: transparent !important;
}
input:-moz-autofill {
  background-color: transparent !important;
}
.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  input {
    padding: 10px 38px 10px 50px !important;
    background: transparent !important;
    // border: 1px solid var(--borderColor) !important;
    &::placeholder {
      color: var(--sidebarLink);
    }
  }
  .search-button {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 !important;
    height: 22px;

    .anticon {
      svg {
        width: 17px;
        fill: var(--whiteFFF);
      }
    }
    input {
      padding-right: 40px;
    }
  }
}

.customInput-rightIcon{
   width: 158px;
}