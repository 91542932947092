.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px 30px 40px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* hiding first and last lines in cartesian grid */
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-cartesian-axis-line {
  stroke: black;
  fill: black !important;
  stroke-width: 0.6;
}
.graph_size {
  display: flex;
  align-items: center;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    color: var(--white);
    border: none;
    padding: 8px 15px;
    border-radius: 40px;
    cursor: pointer;
    font-size: var(--largeParaGraph16);
    div {
      width: 40px;
      height: 10px;
    }
    .pinkColor {
      background-color: var(--pink);
    }
    .blueColor {
      background-color: #0E6AAD;
    }
  }
}
.strike-through {
  text-decoration: line-through;
}
