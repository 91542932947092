.customButton {
  margin-top: 20px;
  padding: 8px 20px;
  width: 100%;
  border-radius: 48px;
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-weight: 600;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  white-space: nowrap;
  outline: none;

  &:disabled {
    p{

      color: var(--buttonBg);
    }
    pointer-events: none;
  }

  .custom-file {
    color: white;
  }

  &:hover {
    .custom-file {
      color: var(--buttontext);
    }


  }

  p {
    color: var(--whiteFFF) !important;
    font-size: var(--largeParaGraph16) !important;
  }

  @media (max-width: 767px) {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  &-regular {
    background: var(--buttonBg);
    color: var(--whiteFFF);
    max-width: min-content !important;

    &:hover {
      // background: var(--buttonHoverBg);
      background-color: transparent;
      border-color: var(--buttonBg);

      p {

        color: var(--buttonBg) !important;
      }

      svg {
        path {
          fill: var(--buttonBg);
        }
      }
    }
  }

  &-smallBtn {
    background: var(--buttonBg);
    color: var(--whiteFFF);
    max-width: 157px;
    font-size: var(--mediumParaGraph);
    height: 36px;
    border: 1px solid transparent;

    &:hover {
      background: transparent;
      border-color: var(--buttonBg);


      p {
        color: var(--buttonBg) !important;
      }

      .customButton-icon {
        svg {
          path {
            fill: var(--buttonBg);
          }
        }
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;

    svg {
      vertical-align: middle;
    }
  }

  &-gray {
    background: var(--grayBtnBg);
    color: var(--mainGrayText);

    &:hover {
      color: var(--whiteFFF);
    }
  }

  &-borderBtn {
    background: transparent;
    border: 1px solid var(--buttonBg);
    width: min-content;

    p {
      color: var(--buttonBg) !important;
    }

    svg {
      path {
        fill: var(--buttonBg);
      }
    }

    &:hover {
      opacity: 0.8;
      background: var(--buttonBg);

      svg {
        path {
          fill: var(--whiteFFF);
        }
      }

      p {
        color: var(--whiteFFF) !important;
      }
    }
  }

  &-smallborderBtn {
    background: transparent;
    color: var(--pinkColor);
    border: 1px solid var(--pinkColor);
    max-width: 157px;
    font-size: var(--mediumParaGraph);
    height: 36px;

    &:hover {
      opacity: 0.8;
      background: var(--pinkColor);
      color: var(--whiteFFF);
    }
  }
}


.editBtn {
  padding: 8px;
  background: var(--inputBg);
  margin-top: 0;
  color: var(--mainTextColor);
  font-size: var(--smallParagraph);
  font-weight: 400;

  &:hover {
    color: var(--white);
  }
}

.fluid_btn {
  max-width: 100% !important;
}

.margin-unset {
  margin: unset;
}

.custom-input {
  display: none;
}

.label-unset {
  cursor: unset !important;
}