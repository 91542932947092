.noLonger {
  &_inner {
    background: var(--SideBarBg);
    border-radius: 48px;
    padding: 30px;
    text-align: center;
    h4 {
      color: var(--rechartLine);
      font-weight: 700;
      font-size: var(--headingTwo);
    }
    img {
      max-width: 200px;
      width: 100%;
      @media screen and (max-width: 767px) {
        max-width: 150px;
      }
      @media screen and (max-width: 490px) {
        max-width: 100px;
      }
    }
  }
}
