.pagination_text {
  position: absolute;
  left: 0;
  color: #707070;
  cursor: auto;
  font-size: var(--headingFour);
  font-weight: 600;
  padding-right: 100px;
}

.ant-pagination-item {
  display: none !important;
}
.ant-pagination {
  padding-right: 14px;
}

.pagination_btn {
  color: white;
  font-size: var(--headingFour);
  display: flex;
  align-items: center;
  white-space: wrap;
  gap: 7px;
  margin-left: 20px;

  &:hover {
    color: var(--pinkColor);
    transition: 0.4s all ease-in-out;
  }

  svg {
    font-size: var(--headingFour);
  }
}
