.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: calc(100vh - 340px);
  margin: 0 auto;
}

.no-data-message {
  font-size: var(--mediumParaGraph);
  color: #666;
  margin-top: 10px;
}

.no-data-icon {
  img {
    max-width: 76px;
    width: 100%;
  }
}
