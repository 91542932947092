.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: black;
  backdrop-filter: blur(4px);
}

.loader-image {
  width: 180px;
  height: 180px;
  @media (max-width: 767px) {
    width: 80px;
    height: 80px;
  }
}
