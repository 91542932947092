.dashCardSekelton {
  width: 100%;
  display: flex;
  gap: 50px;
  height: 100%;

  @media screen and (max-width: 1290px) {
    flex-flow: wrap;
    gap: 20px;
  }
}

.dashCardWrapper {
  display: flex;
  gap: 20px;

  @media screen and (max-width: 1290px) {
    flex-flow: wrap;
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    gap: 15px;
  }

  @media screen and (max-width: 575px) {
    gap-row: 0px;
  }

  .create-card {


    .dashCard_inner {
      position: relative;
      overflow: hidden;

      &::after {
        background-image: url('../../../Assets/images/create-card-bg.png');
        width: 190px;
        height: 120px;
        right: 0;
        top: 0;
        z-index: 0;
        position: absolute;
        background-repeat: no-repeat;
        content: '';
      }
    }

  }

  .upload-card {
    .dashCard_inner {
      position: relative;
      overflow: hidden;

      &_left {
        h3 {
          position: relative;
          z-index: 1;
        }

        p {
          position: relative;
          z-index: 1;
        }
      }

      &::after {
        // border-radius: ;
        background-image: url('../../../Assets/images/upload-card.png');
        width: 195px;
        height: 120px;
        right: 0;
        top: 0;
        z-index: 0;
        position: absolute;
        background-repeat: no-repeat;
        content: '';
      }
    }
  }

  .dashCard_inner {

    // position: relative;
    &_left {
      h3 {
        color: var(--sidebarLink);
      }

      p {
        color: var(--storageClr);
      }
    }

    &_right {
      .customButton {
        z-index: 1111;
      }
    }
  }

  .dashCard {
    flex: 1;
  }

  .target-button {
    width: unset !important;
  }
}