.selectAccount {
  margin-top: 60px;
  display: flex;
  gap: 23px;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
}

.personalAccount {
  margin-top: 30px;

  &_email {
    margin-top: 30px;
  }

  &_selector {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;

    .customSelect {
      width: 100%;
      margin: 0;
    }
  }

  &_btns {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 60px;

    a {
      .customButton {
        max-width: 100% !important;
        height: 50px;
        font-weight: 500;
        width: 100% !important;
      }
    }

    .customButton {
      max-width: 100% !important;
      height: 50px;
      font-weight: 500;
    }

    &_back {
      width: 100%;

      .customButton-icon {


        svg {
          path {
            // fill: var(--sidebarLink);
          }

        }
      }

      &:hover {
        .customButton-icon {
          svg {
            path {
              // fill: var(--whiteColor) !important;
            }
          }
        }
      }

      svg {
        rotate: 180deg;
      }
    }

    a {
      flex: 1;
    }

    .customButton {
      flex: 1;
    }
  }
}

.forgetText {
  text-align: center;
  margin-top: 20px;
  max-width: 164px;
  width: 100%;

  a {
    color: var(--pinkColor);
    text-decoration: underline;
    font-size: var(--largeParaGraph);
    font-weight: 400;
  }
}

.welcomeSec {
  margin-top: 30px;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .customButton {
    height: 50px;
    font-weight: 400;

    @media screen and (max-width: 991px) {
      height: 40px;
    }
  }
}

.resendOtp-heading {
  color: var(--sidebarLink);
  font-size: 18px;
  line-height: 1.4;
}

.userEmail {
  color: var(--pinkColor);
  font-weight: bold;
}

.resendOtp {
  display: flex;
  justify-content: end;
  max-width: 567px;
  width: 100%;

  @media screen and (max-width: 991px) {
    max-width: 635px;
  }

  &_btn {
    color: var(--pinkColor);
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
  }

  .resend-otp-text {
    margin-top: 20px;
    font-size: 15px;
    color: var(--pinkColor);
  }
}

.otp-field>div {
  margin-top: 30px;
  justify-content: center;
  gap: 10px;

  input {
    background: var(--otpInput);
    border-radius: 25px;
    max-width: 5rem !important;
    width: 100% !important;
    height: 50px;
    font-size: 16px;
    border: 1px solid var(--btndarkred);
    color: var(--sidebarLink);
    font-weight: 400;
    font-size: var(--headingFour);

    @media screen and (max-width: 590px) {
      font-size: var(--largeParaGraph);
      height: 40px;
    }

    &:focus-visible {
      outline: none;
      border: none;
    }
  }
}

.personalAccount_btns {
  margin-top: 30px;

 
   
   
}

.disconnectBtn {
  flex-direction: row;
  gap: 5px;
  width: 100% !important;
  justify-content: space-around;

  .customButton-icon {
    margin-left: 17px;
  }

  &:hover {
    .disconnectBtn_inner {
      span {
        color: var(--sidebarLink);
      }
    }
  }

  &_inner {
    display: flex;
    justify-content: end;

    span {
      font-size: var(--largeParaGraph16);
      font-weight: 700;
      color: var(--whiteColor);
    }
  }
}

.flex_download {
  display: flex;
  justify-content: space-between;
}

// .customButton-icon.download {
//   color: #fff;
  
//   svg {
//     path {
//       fill: #fff !important;
//     }
//   }
// }