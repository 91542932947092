.headerCommon {
  padding: 30px 50px;
  border-bottom: 1px solid var(--grayColor);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    padding: 15px 20px;

    img {
      max-width: 83px;
    }
  }

  .contact_disconnect {
    display: flex;
    align-items: center;
    gap: 20px !important;

    >span>svg {
      path {
        fill: var(--buttonBg);
      }

      cursor: pointer;
      margin-top: 23px;
    }
  }

  .disconnect_btn {
    svg {
      path {
        fill: white;
      }
    }
    &:hover{
      svg{
        path{
          fill: var(--buttonBg);
        }
      }
    }

  }

  &_signBtn {
    .customButton {
      width: 145px !important;
      font-size: var(--mediumParaGraph) !important;
      font-weight: 400;
      margin: 0 !important;

      @media screen and (max-width: 676px) {
        width: 90px !important;
        height: 30px !important;
      }
    }
  }
}