.sidebar {
  height: 100vh;
  padding: 26px;
  max-width: 316px;
  width: 100%;
  background: var(--SideBarBg);
  transition: all 0.4s ease;
  z-index: 9;
  transform: translateX(0);
  opacity: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-right: 1.5px solid var(--borderColor2);
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;

    background-image: url('../../../Assets/images/create-card-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    width: 200px;
    height: 200px;
  }

  @media(min-width:992px) {
    padding-right: unset;
  }

  @media (max-width: 992px) {
    padding: 21px 13px;
    max-width: 300px;
    height: 100vh;
  }

  &_logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 31px;
    border-bottom: 1px solid var(--borderColor2);
    .logo_img{
      height: 30px;
      width: 30px;
    }
    a {
      font-size: 30px;
      display: flex;
      gap: 4px;
      line-height: 35px;
      font-weight: 800;
      color: var(--sidebarLink);
    }
  }

  &_bottom {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 22px;
    justify-content: space-between;
  }

  &_link {
    &_items {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;

      a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #67717D;
        font-size: var(--largeParaGraph);
        font-style: normal;
        font-weight: 400;
        padding: 20px 0;
        width: 100%;
        cursor: pointer;

        @media (max-width: 767px) {
          padding: 11px 10px;
          font-size: var(--mediumParaGraph);

          img {
            height: 16px;
          }
        }

        &:hover,
        &.active {
          font-weight: 500;
          color: var(--sidebarLink);
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

          svg {
            path {
              fill: var(--pinkColor);
            }
          }
        }
      }
    }
  }
}

.btnToggle {
  border: 0;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 35px;
  transition: 0.3s all;
  position: relative;
  height: 24px;
  transition: all 0.3s all;

  &:hover {
    cursor: pointer;

    span {
      background-color: var(--pinkColor);
    }
  }

  span {
    background-color: var(--toggleBtn);
    height: 5px;
    border-radius: 10px;
    width: 35px;
    position: absolute;
    left: 0;
    z-index: 1;
    transition: all 0.3s ease;
    transform: rotate(180deg);

    @media (max-width: 767px) {
      width: 30px;
      height: 4px;
    }

    @media (max-width: 490px) {
      width: 24px;
    }

    &:nth-child(2) {
      top: 12px;

      @media (max-width: 767px) {
        top: 10px;
      }

      @media (max-width: 490px) {
        top: 9px;
      }
    }

    &:first-child {
      top: 0;
    }

    &:last-child {
      top: 24px;

      @media (max-width: 767px) {
        top: 20px;
      }

      @media (max-width: 490px) {
        top: 18px;
      }
    }
  }
}

.closed {
  span {
    transition: all 0.3s ease;

    &:nth-child(2) {
      width: 0;
      opacity: 0;
    }

    &:first-child {
      transform: rotate(225deg);
      width: 40px;
      top: 10px;

      @media (max-width: 767px) {
        transform: rotate(220deg);
        width: 24px;
      }
    }

    &:last-child {
      transform: rotate(140deg);
      width: 40px;
      top: 10px;

      @media (max-width: 767px) {
        transform: rotate(140deg);
        width: 24px;
      }
    }
  }
}