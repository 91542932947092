.setting {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-left: 0 !important;
  margin-right: 0 !important;
  &_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 371px;
    width: 100%;
    padding: 33px 30px !important;
    @media screen and (max-width: 767px) {
      padding: 20px !important;
    }
    &_top {
      padding-bottom: 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 20px 0;
        color: var(--midGrayColor);
        font-size: var(--largeParaGraph16);
      }

      h4 {
        color: var(--sidebarLink);
        font-size: var(--headingTwo) !important;
        font-weight: 700;
      }
    }
    &_bottom {
      &_list {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 0;
        border-top: 1.5px solid var(--cardBorder);
        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
          path {
            fill: var(--pink);
          }
        }
        h3 {
          max-width: 250px;
          font-size: var(--largeParaGraph) !important;
          font-weight: 600;
          color: var(--grayColor);
        }
      }
      &_switch {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
      }
      h5 {
        font-size: var(--largeParaGraph);
        font-weight: 600;
      }
      .customButton {
        width: unset;
        height: 36px;
        padding: 13px 30px;
        font-size: var(--mediumParaGraph);
      }
    }
  }
}

.upperCase {
  text-transform: capitalize;
}
.setting_card_bottom_list {
  cursor: pointer;
}
