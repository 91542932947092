.team {
  &_head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-top: 2px;
    gap: 15px;
    flex-flow: wrap;
    @media screen and (max-width: 676px) {
      align-items: end;
    }
    &_search_members {
      display: flex;
      align-items: center;
      gap: 35px;
      @media (max-width: 768px) {
        flex-flow: wrap;
        gap: 20px;
      }
      .customInput {
        max-width: 313px;
        width: 100%;
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }

    &_received_from_members {
      min-width: 200px;
      cursor: pointer;
      color: white;
      white-space: nowrap;
    }
    &_received_from_members:hover {
      color: var(--pinkColor);
      transition: 0.4s all ease-in-out;
    }
    &_left {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 1;
      flex-flow: wrap;
      .customSelect {
        max-width: 233px;
        width: 100%;
      }
    }
    &_right {
      .customButton {
        margin: 0;
      }
    }
  }
}
.accesLevel {
  width: 60px;
  text-align: center;
}
.teamSelect {
  max-width: 400px;
}
.confirmationRemoveModal {
  max-width: 450px !important;
  .modal-content-paragraph {
    display: none;
  }
  &_inner {
    h4 {
      color: var(--sidebarLink);
      font-size: 15px;
      text-align: center;
      font-weight: 500;
      // padding-bottom: 10px;
    }
  }
  &_btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    a {
      flex: 1;
    }
  }
  .customButton {
    p {
      width: 63px !important;
    }
  }
}
.changeAccess {
  .modal-header {
    .icon {
      svg {
        path {
          fill: var(--pinkColor);
        }
      }
    }
  }
  .modal-content-paragraph {
    display: none;
  }
  .customSelect {
    margin: 0 !important;
  }
}
.searchSelectContainer {
  .rc-virtual-list-holder {
    > div {
      overflow-y: unset !important;
    }
  }
}
.addMemberModal {
  .modal-content-botton {
    .customButton {
      p {
        width: 70px !important;
      }
    }
  }
}
