.customSelect {
  margin-top: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;


  label {
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    display: inline-block;
    margin: 0 0 12px 24px;

    color: var(--storageClr);

    span {
      color: var(--pinkColor);
      margin-left: 4px;
    }
  }

  .ant-space-item {
    width: 100%;
  }

  .ant-select-selection-placeholder {
    color: var(--grayColor) !important;
    font-size: var(--mediumParaGraph);
  }

  .ant-select {
    width: 100%;

    &:hover {
      // border: 1px solid #ff0083;
      border-radius: inherit;
    }


    .ant-select-selector {
      border: none;
      outline: unset;
      background: var(--selectBg) !important;
      font-size: var(--mediumParaGraph);
      color: var(--sidebarLink) !important;
      border: 1px solid var(--selectBorder);
      font-weight: 500;
      border-radius: 25px;
      padding: 19px 25px;
      width: 100%;
      height: 50px;
      text-align: left;
      transition: all 0.4s ease-in-out;


      &:hover {
        border: 1.5px solid var(--pinkColor) !important;
      }
    }

    &.ant-select-focused .ant-select-selector {
      border-color: var(--pinkColor) !important;
    }

    &-arrow {
      color: var(--sidebarLink);
      inset-inline-end: 25px;
    }
  }

  .ant-select-item-empty {
    color: var(--whiteFFF);
  }

  .ant-select-selection-search {
    input {
      padding: 0 15px !important;
    }
  }

  .ant-select-open {
    .ant-select-selection-item {
      color: var(--sidebarLink) !important;
    }
  }

  .ant-select-dropdown {
    background-color: var(--dropdownBg);
    border-radius: 15px;
    border: 1px solid var(--borderColor2);
    box-shadow: var(--boxShadow);

    .rc-virtual-list-holder-inner {
      gap: 3px !important;
    }

    .ant-select-item {
      color: var(--sidebarLink) !important;
      border-radius: 10px;
      padding: 5px 20px !important;

      &-option {
        &-content {
          font-size: var(--mediumParaGraph);
        }
      }

      &:hover {
        background-color: var(--hoverTable);
      }

      &-option-selected {
        color: var(--pinkColor) !important;
        border-radius: 10px;
        background-color: var(--hoverTable);
      }
    }
  }

  .ant-select-single {
    height: 100%;
  }
}

.customSelect-border {
  margin: 0;

  .ant-select-selector {
    // border: 1px solid var(--borderColor) !important;
    outline: unset;
    background: #000 !important;
    border-radius: 48px !important;
    padding: 11px 25px !important;
    height: 36px !important;
  }

  .ant-select-dropdown {
    border: 1px solid var(--borderColor) !important;
    background: #000 !important;
  }
}

.descriptionDiv {
  margin-top: 20px;
  padding: 10px 20px;
  background: var(--shareFolder);
  border-radius: 10px;
  border-left: 4px solid #5678;
}

.ant-select-selection-item-remove {
  .anticon {
    svg {
      fill: var(--pinkColor) !important;
    }
  }
}

.ant-select-multiple {
  .ant-select-selector {
    padding: 8px 25px !important;
  }
}

.ant-select-multiple .ant-select-selection-item {
  background: var(--btndarkred) !important;
}

.selectNoData {
  min-height: 100px !important;
  height: 100% !important;

  img {
    max-width: 50px !important;
    width: 100%;
    margin: 0 auto;
  }

  .no-data-message {
    font-size: var(--mediumParaGraph);
    color: var(--sidebarLink);
    margin-top: 10px;
  }
}