.fileManagement {
  &_heading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 20px;
    flex-flow: wrap;

    &_btn {
      display: flex;
      align-items: center;
      gap: 10px;

      .customButton {
        margin: 0 !important;
      }

      .add-folder-btn {
        // background-color: red !important;
        &:hover {
          svg {
            g {
              path {
                stroke: var(--addFolderClr);
              }
            }
          }
        }
      }
    }

    h2 {
      white-space: nowrap;
    }

    &_shared_container {
      white-space: nowrap;
      cursor: pointer;
      color: var(--midGrayColor);
      transition: 0.4s all ease-in-out;
    }

    &_shared_container:hover {
      color: var(--pinkColor);
      transition: 0.4s all ease-in-out;
    }

    &_text {
      align-items: center;
      gap: 20px;
      margin: 0;
      flex: 1;

      flex-flow: wrap;
    }
  }
}

.fileManageFolder {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  button {
    margin-top: 0;
  }

  &_left {
    display: flex;
    gap: 30px;
    align-items: center;

    h2 {
      color: var(--white);
      font-size: var(--headingTwo);
      font-weight: 600;
    }

    .inputBtns {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &_right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.fileManage {
  &_containerTopText {
    color: var(--white);
    font-size: var(--headingFour);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: var(--midGrayColor);
    }
  }

  .ant-tabs-extra-content {
    flex: 1;
    margin-right: 30px;
  }

  .ant-tabs-nav-wrap {
    flex: unset !important;
  }
}

.downLoadIcon,
.plusIcon {
  width: 36px;
  height: 36px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    path {
      fill: var(--pinkColor);
    }
  }
}

.downLoadIcon {
  background-color: var(--pinkColor);

  svg {
    path {
      fill: var(--white);
    }
  }
}

.plusIcon {
  border: 1px solid var(--pinkColor);
}

.folderMore {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px 10px;
  width: 100%;

  svg {
    color: #ff0083 !important;
    height: 18px;
    width: 18px;

    path {
      fill: var(--pinkColor) !important;
    }
  }
}

.fileFolder {
  &_cards {
    padding-top: 30px;
    display: flex;
    flex-flow: wrap;
    gap: 40px 20px;

    &_inner {
      // background: var(--SideBarBg) !important;
      // border: 1px solid var(--SideBarBg);
      overflow: hidden;
      padding: 0;
      max-width: 365px;
      width: 100%;
      // border-radius: 30px;
      // border: 1px solid var(--hoverTable) !important;
      position: relative;

      img {
        cursor: pointer;
        width: calc(375px - 10px);
        height: 177px;
        border-radius: 30px 30px 0 0;
        object-fit: cover;
      }

      .fileFolderdata {
        border-top: 1px solid var(--hoverTable);
        padding: 20px;

        h3 {
          color: var(--sidebarLink);
          font-size: var(--headingTwo);
          font-weight: 600;
          word-break: break-all;
        }

        span {
          color: var(--gray7D);
        }

        &_icons {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .threeDot {
            transition: all 0.4s ease-in-out;
            padding: 5px !important;
            border-radius: 10px;
            height: 26px;
            display: flex;
            align-items: center;

            &:hover {
              background-color: var(--pinkColor);

              svg {
                path {
                  fill: var(--white) !important;
                }
              }
            }
          }

          span {
            display: flex;
            gap: 20px;
          }
        }
      }
    }
  }
}

.toggle {
  display: flex;
  gap: 10px;
}

.pagination_text {
  position: absolute;
  left: 0;
  color: #707070;
  cursor: auto;
  font-size: var(--headingFour);
  font-weight: 600;
  padding-right: 100px;
}

.ant-pagination-item {
  display: none !important;
}

.ant-pagination {
  padding-right: 14px;
}

.pagination_btn {
  color: white;
  font-size: var(--headingFour);
  display: flex;
  align-items: center;
  white-space: wrap;
  gap: 7px;
  margin-left: 20px;
}

.shareContainer {
  h4 {
    font-size: var(--headingTwo);
    font-weight: 700;
    margin-bottom: 20px;
  }

  textarea {
    margin-bottom: 20px;
    max-height: 107px;
  }
}

.ant-dropdown-menu {
  background: var(--dropdownBg) !important;
}

.fileManageTabs {
  margin-top: 30px;

  .ant-tabs-nav-wrap {
    justify-content: end;
  }

  .ant-tabs-tab {
    padding: 0 !important;
    margin: 0 0 0 10px !important;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.folderManage {
  &_breadCrumb {
    display: flex;
    align-items: center;
    gap: 10px;

    a {
      font-size: var(--largeParaGraph);
      color: var(--sidebarLink);
      font-weight: 500;
    }
  }

  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;
    gap: 20px;

    .customInput {
      label {
        display: none;
      }
    }
  }

  .fileManagement_heading_btn {
    @media screen and (max-width: 362px) {
      flex-flow: wrap;
    }
  }

  .ant-tabs-tab {
    align-items: flex-end;
  }

  .ant-tabs-nav {
    gap: 20px;

    .ant-tabs-extra-content {
      flex: 1 !important;
    }

    .ant-tabs-nav-wrap {
      flex: unset !important;

      &:last-child {
        flex: unset !important;
      }
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 18px !important;
  }
}

.profileOption {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
  cursor: pointer;

  svg {
    fill: var(--pinkColor);
  }
}

.gridFolder {
  display: flex;
  gap: 30px;
  flex-flow: wrap;

  &_icons {
    display: flex;
    gap: 10px;
    align-items: first baseline;
    justify-content: center;
  }

  &_item {
    cursor: pointer;
    height: 100%;

    &:hover {
      h5 {
        text-overflow: none;
        overflow-x: unset;
        white-space: wrap;
      }
    }

    img {
      max-width: 250px;
      width: 100%;
      height: 250px;
    }

    h5 {
      text-align: center;
      color: var(--white);
      font-size: var(--largeParaGraph16);
      font-weight: 600;
      margin-bottom: 10px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
      word-break: break-all;
      max-width: 70px;
      width: 100%;
    }
  }
}

.padding {
  padding: 5px 10px;
}

.only_header {
  tbody {
    display: none !important;
  }
}

.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.small_loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loader {
  border: 5px solid var(--pinkColor);
  border-top: 5px solid transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.infinite-scroll-component > h2 {
  text-align: center;
  color: var(--pinkColor);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.scroll-hide {
  overflow: hidden !important;
}

.bucket {
  align-items: center;
  display: flex;
  gap: 10px;

  img {
    width: 25px;
    height: 25px;
  }
}

.container-ellipsis > span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.container-ellipsis img {
  width: 25px;
  height: 25px;
}

.container-ellipsis span > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 180px;
  width: 100%;
}

.containerModal_bottom {
  padding: 10px;
}

.fileManagementTable {
  .ant-table-tbody {
    .ant-table-cell {
      &:last-child {
        text-align: right !important;
        min-width: 100px !important;
      }
    }
  }
}

.margin-top {
  margin-top: 20px;
}

.accessLevel {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  h4 {
    font-size: var(--mediumParaGraph);
    font-weight: 400;
    display: inline-block;
    color: var(--midGrayColor);
  }

  p {
    font-size: var(--mediumParaGraph);
    color: var(--white);
    font-weight: 500;
    line-height: normal;
  }

  &_field {
    background: var(--inputBg);
    color: var(--whiteFFF);
    border-radius: 25px;
    padding: 16px 25px;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
  }
}

.lock {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 9;

  img {
    height: 35px;
    width: 35px;
  }
}

.container-ellipsis {
  .lock {
    position: static;
    // top: 10px;
    // right: 0px;
    cursor: pointer;
    z-index: 9;

    img {
      height: 40px !important;
      width: 40px !important;
    }
  }
}

.ant-checkbox-wrapper {
  color: #ffffff;
}

.invitationModal_btns {
  display: flex;
  justify-content: space-around;
}
